const Colors = {
  whiteText: "#fffffffa",
  offWhite: "#f9f9f9",
  primary: '#02C28D',
  red: "#ff463d",
  yellow: "#faff71",
  primaryGreen: "#36ba8c",
  dividerGray: "#f0f0f0"
}

export default Colors