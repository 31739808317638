import React from 'react'
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import moment from 'moment';
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  withStyles,
} from '@material-ui/core';

import './ResidentsList.css';

var move_in_date_headerColor = 'black';
var status_headerColor = '#828282'
var name_headerColor = '#828282';
var dateIconDisplay = 'inline-block'
var nameIconDisplay = 'none'
var statusIconDisplay = 'none'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    overflowY: 'scroll',
    overflowX: 'scroll',
  },
  tableContainer: { maxWidth: '100%', overflowX: 'hidden' },
  table: {
    width: '100%',
    '& .muitable root': {
      width: '100px',
    }
  },
  tableHead: {
    width: '700px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#d8d8d8',
  },
  tableHeadRow: {
    width: '100%',
    margin: '0',
  },
  moveDate: {
    width: '20%',
    height: '100%',
    verticalAlign: 'center',
  },
  status: {
    width: '20%',
    height: '100%',
    verticalAlign: 'center',
  },
  unit: {
    width: '20%',
    height: '100%',
    verticalAlign: 'center',
  },
  name: {
    width: '40%',
    height: '100%',
    verticalAlign: 'center',
  },
  tablebody: {
    display: 'flex',
    flexorder: 'column',
    width: "100%"
  },
  residentTableRow: {
    flexorder: 'row',
    width: '100%',
    alignItems: 'center'
  }
});

const TableCellMoveDate = withStyles((theme) => ({
  root: {
    maxWidth: '50px'
  },
}))(TableCell);

const TableCellUnit = withStyles((theme) => ({
  root: {
    maxWidth: '30px',
  },
  body: {
    maxWidth: '30px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}))(TableCell);

const TableCellName = withStyles((theme) => ({
  root: {
    maxWidth: '100px',
  },
  body: {
    maxWidth: '100px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}))(TableCell);

const TableCellStatus = withStyles((theme) => ({
  root: {
    maxWidth: '90px'
  },
}))(TableCell);

class ResidentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      residents: [],
      selectedId: props.selectedId,
      badge: false,
      icon: props.icon,
      showArchive: props.showArchive,
      order: 'asc',
      sortBy: 'moveDate',
    }
    this.onSelectResident = this.onSelectResident.bind(this);
    this.renderTableHead = this.renderTableHead.bind(this)
  }

  componentWillReceiveProps(newProps) {
    const residents = this.sortBy(
      this.state.sortBy,
      this.state.order,
      newProps.residents,
      newProps.showArchive
    )
    this.setState({
      selectedId: newProps.selectedId,
      icon: newProps.icon,
      showArchive: newProps.showArchive,
      residents: residents
    });
  }

  onSelectResident(residentId) {
    this.props.onSelectResident(residentId);
  }

  arrayToObject(array, key) {
    var residentObj = array.reduce((obj, item) => {
      obj[item[key]] = item;
      return obj;
    }, {})
    return residentObj;
  }

  sortBy(key, order, res, showArchive) {
    if (res === null || res === undefined) { return [] }
    let residents = Object.values(res)
      .filter(resident => showArchive ? true : !resident.archive)
    // key equal to sortBy type
    switch (key) {
      case 'moveDate':
        residents.sort((first, last) => {
          return order === 'asc'
            ? first.moveDate - last.moveDate
            : last.moveDate - first.moveDate
        });
        return residents
      case 'name':
        return order === 'asc'
          ? residents.sort((first, second) =>
            !first.name.localeCompare(second.name)
          )
          : residents.sort((first, second) =>
            first.name.localeCompare(second.name)
          )
      case 'status':
        return order === 'asc'
          ? residents.sort((first, second) =>
            (first.inviteTempAccount !== second.inviteTempAccount && first.inviteTempAccount) || // invite first
            (first.inviteTempAccount === second.inviteTempAccount && // less tasks first
              (first.completedTasks ?? 0) < (second.completedTasks ?? 0)
            )
          )
          : residents.sort((first, second) => // not'ing the asc order
            !((first.inviteTempAccount !== second.inviteTempAccount && first.inviteTempAccount) ||
              (first.inviteTempAccount === second.inviteTempAccount &&
                (first.completedTasks ?? 0) < (second.completedTasks ?? 0)
              ))
          )
      case 'unit':
        return order === 'asc'
          ? residents.sort((first, second) =>
            !(first.unit ?? '').localeCompare(second.unit ?? '')
          )
          : residents.sort((first, second) =>
            (first.unit ?? '').localeCompare(second.unit ?? '')
          )
      default: return residents
    }
  }

  onSortClicked(key) {
    var update = { sortBy: key };
    if (key === 'firstName') {
      nameIconDisplay = 'inline-block'
      dateIconDisplay = 'none'
      statusIconDisplay = 'none'
      name_headerColor = 'black'
      move_in_date_headerColor = '#828282'
      status_headerColor = '#828282'
    }
    else if (key === 'moveDate') {
      dateIconDisplay = 'inline-block'
      nameIconDisplay = 'none'
      statusIconDisplay = 'none'
      move_in_date_headerColor = 'black'
      status_headerColor = '#828282'
      name_headerColor = '#828282'
    }
    else if (key === 'status') {
      statusIconDisplay = 'inline-block'
      nameIconDisplay = 'none'
      dateIconDisplay = 'none'
      status_headerColor = 'black'
      move_in_date_headerColor = '#828282'
      name_headerColor = '#828282'
    }

    if (key === this.state.sortBy) {
      update.order = this.state.order === 'asc' ? 'desc' : 'asc'
      update.residents = this.sortBy(key, update.order, this.state.residents)
    }

    this.setState(update);
  }

  btnStatus(resident) {
    var colors = {
      backgroundColor: '#ff463d',
      color: '#fffffffa'
    }
    if (resident.numTasks && resident.numTasks === resident.completedTasks) {
      colors.backgroundColor = 'green'
      colors.color = 'white'
    }
    else if (resident.numTasks && resident.completedTasks > 0) {
      colors.backgroundColor = '#faff71'
      colors.color = '#000000cb'
    }
    return colors
  }

  renderTableHead() {
    const { classes } = this.props;
    const { residents, selectedId } = this.state
    return (
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.tableHeadRow}>
          <TableCellMoveDate
            align="left"
          >
            <a className='header'
              style={{ color: move_in_date_headerColor }}
              onClick={() => { this.onSortClicked('moveDate') }}
            >
              {"Move-in Date"}
              <i className='material-icons'
                id='icons'
                onClick={() => { this.onSortClicked('moveDate') }}
                style={{ display: dateIconDisplay }}
              >
                {this.state.icon}
              </i>
            </a>
          </TableCellMoveDate>
          <TableCellUnit align="left">
            <a className='header'
              href={() => false}
              style={{ color: status_headerColor }}
              onClick={() => { this.onSortClicked('status') }}
            >
              {"Unit"}
              <i className='material-icons'
                id='icons'
                onClick={() => { this.onSortClicked('unit') }}
                style={{ display: statusIconDisplay }}
              >
                {this.state.icon}
              </i>
            </a>
          </TableCellUnit>
          <TableCellName align="left">
            <a className='header'
              href={() => false}
              style={{ color: name_headerColor }}
              onClick={() => { this.onSortClicked('firstName') }}
            >
              {"Name"}
              {nameIconDisplay &&
                <i className='material-icons'
                  id='icons'
                  onClick={() => { this.onSortClicked('firstName') }}
                  style={{ display: nameIconDisplay }}
                >
                  {this.state.icon}
                </i>
              }

            </a>
          </TableCellName>
          <TableCellStatus
            align="right"
          >
            <a className='header'
              href={() => false}
              style={{ color: status_headerColor }}
              onClick={() => { this.onSortClicked('status') }}
            >
              {"Status"}
              <i className='material-icons'
                id='icons'
                onClick={() => { this.onSortClicked('status') }}
                style={{ display: statusIconDisplay }}
              >
                {this.state.icon}
              </i>
            </a>
          </TableCellStatus>
        </TableRow >
      </TableHead>
    )
  }

  renderTableBody() {
    const { classes } = this.props;
    const { residents, selectedId, } = this.state
    return (
      <TableBody >
        {
          residents
            .map((resident) => this.renderResident(classes, resident, selectedId))
        }
      </TableBody>
    )
  }

  renderResident(classes, resident, selectedId) {
    return (
      resident !== undefined ?
        <TableRow
          key={resident.id}
          button="true"
          divider="true"
          selected={resident.id === selectedId}
          onClick={() => { this.onSelectResident(resident.id) }}
        >
          <TableCellMoveDate align="left">
            {moment(resident.moveDate).format('M/D/YYYY')}
          </TableCellMoveDate>
          <TableCellUnit align="left" >{resident.unit ?? 'none'}</TableCellUnit>
          <TableCellName align="left">
            <Box
              styles={{
                'width': '100px',
                'text-overflow': 'ellipsis'
              }}
              fontSize={"14px"}
            >
              {`${resident.firstName} ${resident.lastName}`}
            </Box>
          </TableCellName>
          <TableCellStatus align="right" > {
            resident.inviteTempAccount
              ? <Chip label={'Invited'} />
              : <Chip
                label={`
                  ${resident.completedTasks === undefined
                    ? 0
                    : resident.completedTasks
                  }
                  \xa0/\xa0 
                  ${resident.numTasks}
                `}
                style={this.btnStatus(resident)}
              />
          } </TableCellStatus>
        </TableRow> : ''
    )
  }

  render() {
    const { classes } = this.props;
    const { residents, selectedId, showArchive } = this.state
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </TableContainer>
    )
  }
}

const mapStateToProps = state => ({
  residents: state.residentsState
})

ResidentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ResidentList);