import * as ResidentActions from './residentActions'
import * as PostActions from './postActions'
const SET_AUTH_USER = 'SET_AUTH_USER'
const SET_APARTMENT = 'SET_APARTMENT'

export {
  SET_AUTH_USER,
  SET_APARTMENT,
  ResidentActions,
  PostActions,
}