export const SET_RESIDENTS = 'SET_RESIDENTS';
export const SET_RESIDENT = 'SET_RESIDENT';
export const REMOVE_RESIDENTS = 'SET_RESIDENTS';
export const REMOVE_RESIDENT = 'REMOVE_RESIDENT';
export const UPDATE_RESIDENT = 'UPDATE_RESIDENT'
export const UPDATE_RESIDENT_INFO = 'UPDATE_RESIDENT_INFO';

// Actions for Reduceres
export const setResidents = residents => ({ type: SET_RESIDENTS, residents: residents});
export const setResident = resident => ({ type: SET_RESIDENT, resident: resident });
export const updateResident = (residentId, update) => 
    ({ type: UPDATE_RESIDENT, residentId : residentId, update: update})
export const removeResidents = () => ({ type: REMOVE_RESIDENTS });
export const removeResident = (residentId) => ({ type : REMOVE_RESIDENT, residentId : residentId });

// Actions for API CALLS
export const listenToApartmentResidents = (firebase, dispatch) => {
    return firebase.listenToApartmentResidents(dispatch)
}
export const listenToResidentTasks = (firebase, dispatch, residentId) => {
    return firebase.listenToResidentTasks(dispatch, residentId)
}
export const listenToResidentInfo = (firebase, dispatch, residentId) => {
    return firebase.listenToResidentInfo(dispatch, residentId)
}

export const updateResidentInfo = (firebase, residentId, residentInfo) => {
    firebase.updateResidentInfo(residentId, residentInfo);
}