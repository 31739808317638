import React from 'react';

import { NotificationContext } from './context'
import { withFirebase } from '../firebase';

const withNotifications = Component => {
    class Notifications extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                notifications : {}
            };
        }

        // listen for notifications
        componentDidMount = () => {
            // this.props.firebase.messaging.onMessage(payload => {
            //     this.handleNotification(payload);
            // });
        }

        handleNotification = (payload) => {
            console.log('Messaging received', payload);
        }

        render() {
            const { notifications } = this.state;
            return  (
                <NotificationContext.Provider value={notifications}>
                    <Component {...this.props} notifications={notifications} />
                </NotificationContext.Provider> 
            );
        }
    };
    return withFirebase(Notifications);
}
export default withNotifications;