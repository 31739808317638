import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './firebase/index';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import './bootstrap.js';

export const store = configureStore();
export const firebase = new Firebase();

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
    .register("../firebase-messaging-sw.js")
    .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
    })    
    .catch(function(err) { 
        console.log("Service worker registration failed, error:", err);
    });
}
  
ReactDOM.render(
    <div className="root">
        <Provider store={store}>
            <FirebaseContext.Provider value={firebase}>
                <App />
            </FirebaseContext.Provider>
        </Provider>
    </div>,
    document.getElementById('root')
);

// serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
