export const LOGIN = '/login';
export const LANDING = '/';
export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const GALLERY = '/gallery';
export const PASSWORD_FORGET = '/pw-forget';
export const COMMUNITY_BOARD = '/communityBoard';
export const APARTMENT_SETUP = '/apartmentSetup';
export const FAQSLIST = '/FAQs';
export const NEWFAQ = '/FaqsForm';
export const FAQ = '/Faq';
export const EDIT = '/Edit';
