export const GENERAL_INFO ='generalInfo';
export const PHOTOS = 'photos';
export const REQUIRED_MOVE_IN_TASKS = 'requiredMoveInTasks';
export const POST_MOVE_IN_TASKS = 'postMoveInTasks';
export const FAQS = 'faqs';
export const IMPORTANT_PHONE_NUMBERS = 'importantPhoneNumbers';
export const TEAM = 'team';
export const AMENITIES = 'amenities';
export const EMAIL_NOTIFICATIONS = 'emailSettings'
export const INTERNAL_TASKS = 'internalTasks'


export const selectMenuItemAction = (view) => {
    switch(view) {
        case "generalInfo":
            return {type: GENERAL_INFO};
        case "photos":
            return {type: PHOTOS};
        case "requiredMoveInTasks":
            return {type: REQUIRED_MOVE_IN_TASKS};
        case "postMoveInTasks":
            return {type: POST_MOVE_IN_TASKS};
        case "faqs":
            return {type: FAQS};
        case "importantPhoneNumbers":
            return {type: IMPORTANT_PHONE_NUMBERS};
        case "team":
            return {type: TEAM};
        case "amenities":
            return {type: AMENITIES};
        case "emailSettings":
            return {type: EMAIL_NOTIFICATIONS}
        case "internalTasks":
            return {type: INTERNAL_TASKS}
        default: 
            return {type: GENERAL_INFO};
    }
}
