import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthentication } from '../../session'
import './TopNavBar.css';

const StyledTabs = withStyles({
  root: {
    color: 'white',
    float: 'left',
    height: 'auto',
    position: 'absolute',
    bottom: '0'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 0',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: '#ffffff',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

class TopNavBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      webUser: '',
      activeTab: props.location.pathname,
      tabs: []
    };
    this.handleTabChange = this.handleTabChange.bind(this)
    this.updateTabs = this.updateTabs.bind(this)
  }

  componentDidMount() {
    this.updateTabs(this.props.apartment);
  }

  componentWillReceiveProps(newProps) {
    const { location, apartment } = newProps;
    var update = {};
    if (this.props.location &&
      location.pathname !== this.props.location.pathname
    ) { update.activeTab = location.pathname; }
    this.setState({
      webUser: newProps.firebase.userEmail(),
      ...update
    });
    this.updateTabs(apartment);
  }

  updateTabs(apartment) {
    if (apartment && apartment.apartmentId) {
      // see if apartment can dispaly communityBoard
      var tabs = [
        <Tab key="residents" label='RESIDENTS' value='/dashboard' />,
        <Tab key="setup" label='SETUP' value='/apartmentSetup' />
      ];
      if (apartment.communityBoardEnabled) {
        tabs.splice(
          1, 0,
          <Tab
            key="community"
            label='COMMUNITY BOARD'
            value='/communityBoard'
          />
        );
      }
      this.setState({ tabs: tabs });
    } else { this.setState({ tabs: [] }); }
  }

  handleTabChange = (e, newValue) => {
    this.props.history.push(newValue);
    this.setState({ activeTab: newValue })
  }

  render() {
    const { tabs } = this.state;
    return (
      <div className={"topNavWrap"}>
        <div className={'logoWrap'}>
          <img src={require('./muvnday_logo_white.png')} alt='IMG' />
        </div>
        <StyledTabs
          className={"tabsContainer"}
          value={this.state.activeTab}
          onChange={this.handleTabChange}
        >
          {tabs}
        </StyledTabs>
        <div className="buttonWrap">
          <div className={'button'}>
            <i className="material-icons" style={{ float: 'left' }}>
              account_circle
            </i>
            <div className='dropdown'>
              {this.state.webUser}
              <i className="material-icons" style={{ float: 'right' }} >
                arrow_drop_down
              </i>
              <div className='dropDownContent'>
                <a href={'/'} onClick={this.props.onSignOut}>Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { apartmentState = { apartment: {} } } = state;
  return apartmentState;
}

export default compose(
  withAuthentication,
  withRouter,
  connect(mapStateToProps),
)(TopNavBar);
