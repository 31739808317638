import { 
    GENERAL_INFO, PHOTOS, REQUIRED_MOVE_IN_TASKS, POST_MOVE_IN_TASKS, 
    FAQS, IMPORTANT_PHONE_NUMBERS, TEAM, AMENITIES, 
    EMAIL_NOTIFICATIONS, INTERNAL_TASKS
} from './actions'

export const INITIAL_STATE = {
    selectedMenuItem: "generalInfo"
}

export function SideMenuReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case GENERAL_INFO:
      return {...state, selectedMenuItem: "generalInfo"};
    case PHOTOS:
      return {...state, selectedMenuItem: "photos"};
    case REQUIRED_MOVE_IN_TASKS:
      return {...state, selectedMenuItem: "requiredMoveInTasks"};
    case POST_MOVE_IN_TASKS:
      return {...state, selectedMenuItem: "postMoveInTasks"};
    case FAQS:
      console.log(state, action)
      return {...state, selectedMenuItem: "faqs"};
    case IMPORTANT_PHONE_NUMBERS:
      return {...state, selectedMenuItem: "importantPhoneNumbers"};
    case TEAM:
      return {...state, selectedMenuItem: "team"};
    case AMENITIES:
      return {...state, selectedMenuItem: "amenities"};
    case EMAIL_NOTIFICATIONS:
      return {...state, selectedMenuItem: "emailSettings"};
    case INTERNAL_TASKS:
      return {...state, selectedMenuItem: "internalTasks"};
    default:
      return state;
  }
}