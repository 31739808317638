import { batch } from 'react-redux';
import ReduxSagaFirebase from 'redux-saga-firebase';
import moment from 'moment';

import { ResidentActions } from '../actions';

const firebase = require('firebase');
require('firebase/auth');
require('firebase/database');
require('firebase/firestore');
// require('firebase/messaging');
require('firebase/storage');

var uuid = require("uuid");


// Firebase config
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.database = firebase.database();
    this.firestore = firebase.firestore();
    this.functions = firebase.functions();
    // this.messaging = firebase.messaging();
    this.storage = firebase.storage();
    this.reduxSagaFirebase = new ReduxSagaFirebase(this.app)
  }

  /* Auth */
  // Create Account
  doCreateuserWithEmailAndPassword = (email, password) =>
    this.auth
      .createUserAndRetrieveDataWithEmailAndPassword(email, password);

  // Sign In
  doLoginWithEmailAndPassword = (email, password) =>
    this.auth.
      signInWithEmailAndPassword(email, password);

  // Sign out
  doSignOut() {
    this.database
      .ref('user_push_messaging_tokens')
      .child('testBuilding')
      .remove()
    this.auth.signOut();
  }

  // Password Reset
  doPasswordReset(email) {
    return new Promise((resolve, reject) => {
      return this.auth.sendPasswordResetEmail(email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  };

  // Password Change
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  /* User */
  uid = () => {
    return this.auth.currentUser !== null
      ? this.auth.currentUser.uid
      : undefined;
  }

  /**
   * @returns {Promise<{User}>} Promise to User object, throws error otherwise
   */
  //broswer notification
  getToken() {
    // const messaging = this.messaging;
    // return messaging.requestPermission()
    //   .then(this.tokenRefresh)
    //   .catch(console.error);
  }

  tokenRefresh() {
    // this.userApartment().then((aptData) => {
    //   const messaging = this.messaging
    //   return messaging.getToken()
    //     .then((token) => {
    //       this.database.ref().child('user_push_messaging_tokens')
    //         .child(aptData.apartmentId)
    //         .set({
    //           instanceID: token,
    //           apartmentId: aptData.apartmentId,
    //           id: aptData.id
    //         })
    //     })
    // });
  }

  userEmail() {
    if (this.auth.currentUser !== null) {
      return this.auth.currentUser['email'];
    }
  }

  //check message function is available
  messageFunctionEnable(residentApartment) {
    var msgEnabled = ''; // default flag to false
    if (this.auth.currentUser !== undefined) {
      // get messageUsEnabled flag
      this.database
        .ref('knownAddresses/' + residentApartment + '/messageUsEnabled')
        .on('value', snapshot => { msgEnabled = snapshot.val() });
    }
    return msgEnabled;
  }

  // switch hasUpdate to false
  readUpdate(id, building) {
    return this.database.ref(`moves/${id}`).update({ hasUpdate: false })
      .then(result => {
        return result;
      });
  }

  // delete record
  deleteRecord(id, building, code) {
    return Promise.all([
      this.firestore.collection('apartments/' + building + '/residents')
        .doc(id).delete(),
      this.firestore.collection('inviteCodes').doc(code).delete()
    ]).then(results => { return 'was deleted' })
  }

  // archiveRecord
  archiveRecord(id, building, archive) {
    return this.firestore.collection('apartments/' + building + '/residents')
      .doc(id)
      .set({ archive: archive }, { merge: true })
  }

  // last invite email send date
  inviteEmailDate(id, building, time) {
    return this.firestore.collection('apartments/' + building + '/residents').doc(id).update({
      emailSendDate: time
    })
  }

  // apartment basic information
  userApartmentDoc() {
    return new Promise((resolve, reject) => {
      if (this.uid()) {
        return this.firestore.collection('apartments')
          .where('id', '==', this.uid())
          .get()
          .then(query => {
            var apartmentDoc = null;
            query.docs.forEach(doc => { apartmentDoc = doc })
            apartmentDoc !== null
              ? resolve(apartmentDoc)
              : reject({
                error: 'Could Not Find ApartmentDoc',
                message: 'Could not retrieve Apartment Doc'
              });
          });
      } else {
        // TODO: Provide Error codes
        reject({
          error: 'No User Logged In',
          message: "No User is logged in"
        });
      }
    })
  }

  userApartment() { return this.userApartmentDoc().then(ref => ref.data()); }

  user = () => {
    return new Promise((resolve, reject) => {
      const uid = this.uid();
      if (uid === undefined) {
        reject({
          error: 'no uid',
          message: 'No user is logged in'
        });
      }

      // query for apartment object
      return this.firestore.collection('apartments')
        .where('id', '==', this.uid())
        .get()
        .then(querySnap => {
          if (querySnap.size !== 1) {
            return reject({
              error: 'Unexpected Result',
              message: 'Could not retrieve user'
            })
          }
          const user = querySnap.docs[0].data()
          return resolve(user);
        });
    });
  }

  /* msg stuff */
  conversationsDataRef() {
    return new Promise((resolve, reject) => {
      resolve(this.database.ref('conversations'));
    });
  }

  getConversation(id, companyId) {
    return this.conversationsDataRef()
      .then(conversationDataRef => {
        return new Promise((resolve, reject) => {
          conversationDataRef.orderByChild('user').equalTo(id).once('value', querySnap => {
            var query = querySnap.val();
            if ((query !== undefined) && (query !== null)) {
              const convo = Object.values(query)[0];
              resolve(convo);
            } else { reject('no conversation found'); }
          });
        });
      });
  }

  /**
   * 
   * @param {String} conversationId 
   * @param {String} name 
   * @param {String} body 
   * @param {String} uid
   * 
   * @returns {Promise<Success|Error>} promise of success or error message
   */
  writeMessages(conversationId, name, body, uid) {
    var date = new Date();
    var newMsgRef = this.database.ref(`conversations/${conversationId}/messages`).push();
    return newMsgRef.set({
      body: body,
      author: {
        name: name,
        id: uid,
      },
      createdAt: date.getTime(),
      id: newMsgRef.key,
    });
  }

  /* User stuff */

  /**
   * @returns {Promise<DocumentReference|Error>} User Document Firestore Reference
   */
  userDoc = () => {
    return this.user()
      .then(user => {
        return this.firestore.doc(`apartments/${user.apartmentId}`);
      })
  }

  /**
   * @returns {Promise<CollectionReference|Error>} Residents Collection Firestore Reference
   */
  residentsCollectionRef = () => {
    return this.userDoc().then(userDoc => userDoc.collection('residents'));
  }

  /**
   * @param {String} resident Id 
   * @returns {Promise<DocumentReference|Error>} Resident Document Reference or error
   */
  residentDocRef = (residentId) => {
    return this.residentsCollectionRef()
      .then(residentsCollectionRef => residentsCollectionRef.doc(residentId))
  }

  /**
   * Retrieves residents of the current user from firestore
   * @returns {Promise<[Object]>} Promise to an array of residents
   */
  getResidents = () => {
    return this.residentsCollectionRef()
      .then(residentsCollectionRef => {
        return residentsCollectionRef.onSnapshot(residentsCollection => {
          // on Success
          let residents = residentsCollection.docs.reduce((residents, residentSnap) => {
            const resident = residentSnap.data();
            resident.id = residentSnap.id;
            residents[resident.id] = resident;
            return residents;
          }, {});
          return residents;
        }, error => {
          // on Error
          throw error
        });
      });
  }

  /**
   * @param {Dispatch} dispatch
   * 
   * @returns {Promise<Listener|Error>} promise of listener or Error 
   */
  listenToApartmentResidents = (dispatch) => {
    return this.residentsCollectionRef().then(residentsCollectionRef => {
      const listener = residentsCollectionRef
        .onSnapshot(residentsSnapshot => {
          const adds = {};
          const updates = [];
          const removals = [];
          residentsSnapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              adds[change.doc.id] = change.doc.data();
              if (change.doc.data().inviteTempAccount) {
                adds[change.doc.id].id = change.doc.id
              }
            }
            if (change.type === "modified") {
              const update = {
                id: change.doc.id, ...change.doc.data()
              };
              updates.push(update);
            }
            if (change.type === "removed") {
              removals.push(change.doc.id);
            }
          });
          batch(() => {
            dispatch(ResidentActions.setResidents(adds));
            updates.forEach(update => {
              dispatch(ResidentActions.updateResident(update.id, update))
            });
            removals.forEach(residentId => {
              dispatch(ResidentActions.removeResident(residentId));
            });
          })
        }, error => { console.error(error); });
      return listener;
    });
  }

  listenToResidentDoc = (dispatch, residentId) => {
    return this.residentDocRef(residentId)
      .then(residentRef => {
        return residentRef.onSnapshot(residentDoc => {
          dispatch(ResidentActions.updateResident(residentId, residentDoc.data()))
        });
      });
  }


  listenToResidentTasks = (dispatch, residentId) => {
    return this.residentDocRef(residentId)
      .then(residentDoc => {
        return residentDoc.collection('tasks')
          .orderBy('dueDate')
          .onSnapshot(tasksSnapshot => {
            this.getResident(residentId).then(resident => {
              dispatch(ResidentActions.updateResident(residentId, resident));
            })
              .catch(console.error);
          });
      });
  }

  /**
   * @param {String} residentId
   * @param {String} residentInfo
   * 
   * @returns {Promise<Success|Error>} promise of success or failuer message
   */
  updateResidentInfo = (residentId, residentInfo) => {
    return this.residentDocRef(residentId)
      .then(doc => doc.set({ residentInfo: residentInfo }, { merge: true }));
  }

  /**
   * 
   * @param {string} residentId id of resident for update
   * @param {Object} changes changes to resident doc in firestore
   */
  saveResidentDetailsChanges = (residentId, changes) => {
    return this.residentDocRef(residentId)
      .then(doc => doc.set(changes, { merge: true }))
      .then(result => {
        // update move doc if move date is changed
        if (changes.moveDate) {
          return this.firestore.doc(`moves/${residentId}`)
            .update({ moveDate: changes.moveDate })
        } else { return result }
      })
  }

  /**
   * @param {String} residentId
   * @returns {Promise<DocumentReference|Error>} 
   */
  residentTasksCollectionRef = (residentId) => {
    return this.residentDocRef(residentId)
      .then(doc => doc.collection('tasks'));
  }

  /**
   * Retrieves the information of resident from firestore
   * 
   * @param {String} residentId Resident Id
   * 
   * @returns {Promise} Promise of Resident Object
   */
  getResident = (residentId) => {
    return new Promise((resolve, reject) => {
      // get resident info
      return this.residentDocRef(residentId)
        .then(residentDoc => residentDoc.get())
        .then(residentDoc => {
          if (!residentDoc.exists) {
            reject("Resident does not exist for this apartment.");
          }
          // get resident tasks
          return residentDoc.ref.collection('tasks')
            .orderBy('dueDate')
            .get()
            .then(tasksCollection => {
              if (tasksCollection.docs.length === 0) {
                return residentDoc.data()
              }
              const tasks = tasksCollection.docs.reduce((tasks, task) => {
                tasks[task.id] = task.data();
                tasks[task.id].submissions = [];
                return tasks;
              }, {});
              const submissionsQuery = tasksCollection.docs
                .map(taskDoc => taskDoc.ref.collection('submissions').get())
              return Promise.all(submissionsQuery)
                .then(taskSubmissionsQueries => {
                  taskSubmissionsQueries.forEach(submissionsQuery => {
                    if (submissionsQuery.docs[0] === undefined) { return }
                    const submissions = submissionsQuery.docs
                      .map(submissionDoc => submissionDoc.data());
                    tasks[
                      submissionsQuery.docs[0].ref.parent.parent.id
                    ].submissions = submissions;
                  })
                  const resident = Object.assign(
                    {},
                    residentDoc.data(),
                    { tasks: tasks },
                    { id: residentDoc.id }
                  );
                  resolve(resident);
                })
            })
        })
        .catch(reject);
    });
  }

  uploadImage = (imageFile) => {
    return this.userApartment()
      .then(apartment => {
        const ref = this.storage
          .ref(`images/${apartment.apartmentId}/`)
          .child(uuid.v4())
        const task = ref.put(imageFile)
        return {
          uploadRef: ref,
          uploadListener: task.on(firebase.storage.TaskEvent.STATE_CHANGED)
        };
      });
  }

  galleryCollection = () => {
    return this.userDoc()
      .then(userDoc => { return userDoc.collection('apartmentPhotos') });
  }


  getAllGalleryPhotos = () => {
    return this.galleryCollection()
      .then(galleryCollection => {
        return galleryCollection.get()
      })
      .then(galleryCollection => {
        return galleryCollection.docs.map(imageDoc => { return imageDoc.data() });
      });
  }

  /*** HTTPS CALLS ***/

  /**
   * @param {Object} inviteDetails object with details for the invite
   *
   * @returns {Promise<{status,message,invite}>} Promise { status, message, invite } onSuccess
   */
  createInviteCodeForApartmentResident = (details) => {
    return this.userApartment()
      .then(apartment => {
        // add apartmentId of user to details
        const inviteDetails = Object.assign(
          {},
          details,
          { apartmentId: apartment.apartmentId }
        );
        const createInviteCode = this.functions
          .httpsCallable('createInviteCodeForApartmentResident');
        return createInviteCode(inviteDetails).then(result => {
          return result.data.invite
        });
      });
  };

  resendCode = (code) => {
    return new Promise((resolve, reject) => {
      var reSendCode = this.functions.httpsCallable('resendInviteEmailForApartmentResident');
      return reSendCode(code)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  };

  createPost = (postText = '', tags = [], media = {}) => {
    return this.user().then(user => {
      var createPost = this.functions.httpsCallable('createNewPost');
      return createPost({
        apartmentId: user.apartmentId,
        postText: postText,
        tags: tags,
        media: media,
      });
    });
  }

  editPost = (postId, text, tags, media) => {
    var update = {
      lastModified: moment().valueOf()
    };
    if (text) { update.text = text }
    if (tags) { update.tags = tags }
    if (media) { update.media = media }
    return this.firestore.doc(`posts/${postId}`).update(update)
  };

  deletePost = (postId) => {
    return this.firestore.doc(`posts/${postId}`).delete();
  }

  getAllPosts = () => {
    return this.userApartmentDoc()
      .then(apartmentDoc => {
        const apartment = apartmentDoc.data();
        return this.firestore.collection(`posts`)
          .where('targets', 'array-contains-any', [apartment.apartmentId, 'public'])
          .orderBy('lastModified', 'desc').get();
      })
      .then(postQuery => {
        // get comments for each post
        return postQuery.docs.map(doc => {
          return doc.data()
        });
      })
  }
};

export const reduxSagaFirebase = new ReduxSagaFirebase(firebase.initializeApp);

export default Firebase;