import { SET_APARTMENT } from '../actions'

const INITIAL_STATE = {
    apartment : {}
}

const applySetApartment = (state, action) => ({
    ...state,
    apartment : action.apartment,
});

const apartmentReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_APARTMENT:
            return applySetApartment(state, action); 
        default: return state;
    }
    return state;
};

export default apartmentReducer;