import React from 'react';
import { compose } from 'recompose'
import {
    Typography,
    CardMedia, Menu, ButtonBase,
    MenuItem, Fade, TextField, CircularProgress, 
} from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../../firebase'
import { PostActions } from '../../actions/actions';
import styles from './OwnedPostStyles'

// Dumb Component
class OwnedPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuContentAnchor: null,
            anchorOrigin: null,
            edit: false,
            loading: false,
            deleted: false,
            text: this.renderText(props.text),
        };
        this.editPostTextRef = React.createRef();
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            text: this.renderText(newProps.text),
        })
    }

    handleOpenMenu = event => {
        this.setState({
            menuContentAnchor: event.currentTarget
        });
    }

    handleCloseMenu = () => {
        this.setState({
            menuContentAnchor: null
        });
    }

    handleEdit = () => {
        this.setState({
            menuContentAnchor: null,
            edit: true
        })
    }
    
    handleSaveEdit = () => {
        const {
            firebase, id
        } = this.props
        const newText = this.editPostTextRef.current.value;
        this.setState({
            edit: false,
            loading: true,
        })
        PostActions.editPost(firebase, id, newText).then(result => {
            console.log(result);
            this.props.onUpdate();
            this.setState({
                loading: false,
            });
        })
        .catch(console.error)
    }

    handleCancelEdit = () => {
        this.setState({
            edit: false
        })
    }

    handleDeletePost = () => {
        const { firebase, id } = this.props;
        this.setState({
            menuContentAnchor: null,
            edit: false,
            loading: true,
        })

        PostActions.deletePost(firebase, id)
        .then(result => { 
            this.props.onUpdate();
            this.setState({ loading: false })
        })
        .catch(console.error);
    };

    renderText = (text) => {
        const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
        const html = text.replace(reg, "<a href='$1$2'>$1$2</a>");
        return ( <div dangerouslySetInnerHTML={{__html: html}} /> )
    }

    render() {
        const {
            thumbnail, poster, date,
            media, classes,
        } = this.props;

        const {
            menuContentAnchor, edit, loading, text
        } = this.state;
        return (
            <div className={classes.root}>
                <div className={classes.poster}>
                    <CardMedia 
                        className={classes.posterImage}
                        image={thumbnail}
                    />
                    <div className={classes.postInfoText}>
                        <Typography 
                            variant="h6" 
                            styles={{ float: "left"}}
                        >
                            {poster}
                        </Typography>
                        <Typography 
                            variant="subtitle2" 
                            component='h2' 
                            styles={{top: '50%', position: 'absolute'}}
                        >
                            {date}
                        </Typography>
                    </div>
                    <ButtonBase 
                        className={classes.moreOptionsButton}
                        onClick={this.handleOpenMenu}
                    >
                        <MoreHoriz />
                    </ButtonBase>
                    <Menu 
                        getContentAnchorEl={null}
                        anchorEl={menuContentAnchor}
                        keepMounted
                        open={Boolean(menuContentAnchor)}
                        TransitionComponent={Fade}
                        onClose={this.handleCloseMenu}
                    >
                        <MenuItem onClick={this.handleEdit} >
                            EDIT
                        </MenuItem>
                        <MenuItem onClick={this.handleDeletePost}>
                            DELETE
                        </MenuItem>
                    </Menu>
                </div>
                { edit
                    ? <div className={classes.editPost}> 
                        <TextField 
                            className={classes.editPostTextField}
                            placeholder="Share an announcement or event..."
                            fullWidth
                            multiline
                            inputRef={this.editPostTextRef}
                            align="center"
                            defaultValue={this.props.text}
                        /> 
                        <div> 
                            <ButtonBase 
                                className={classes.saveButton}
                                onClick={this.handleSaveEdit}
                            > 
                                SAVE 
                            </ButtonBase>
                            <ButtonBase 
                                className={classes.cancelButton}
                                onClick={this.handleCancelEdit}
                            > 
                                CANCEL 
                            </ButtonBase>
                        </div>
                    </div>

                    : <Typography
                        className={classes.postText}
                        variant='subtitle1'
                    >
                        {text}
                    </Typography>
                }
                { media && media.url
                    ? <CardMedia 
                        style={{
                            height: media.url ? '300px' : 'fit-content',
                            float: 'left',
                            'max-height': '300px',
                        }}
                        image={media.url}
                    >
                        {/* <img className={classes.postImage} src={media.url}/> */}
                    </CardMedia>
                    : ''
                }
                {loading && 
                    <div className={classes.loadingView}> 
                        <CircularProgress className={classes.loadingSpinner}/>
                    </div>
                }
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withFirebase,
)(OwnedPost);