export enum ActionTypes {
    SET_SHOW_INVITE_RESIDENT_DIALOG = "SET_SHOW_INVITE_RESIDENT_DIALOG",
    SET_SHOW_LOADING = "SET_SHOW_LOADING",
    SET_SNACK_BAR = "SET_SNACK_BAR"
}

export const setShowInviteResidentDialog =
    (show: boolean): { type: ActionTypes.SET_SHOW_INVITE_RESIDENT_DIALOG, show: boolean } =>
        ({ type: ActionTypes.SET_SHOW_INVITE_RESIDENT_DIALOG, show });

export const setShowLoading =
    (show: boolean): { type: ActionTypes.SET_SHOW_LOADING, show: boolean } =>
        ({ type: ActionTypes.SET_SHOW_LOADING, show })

export const setSnackBar =
    (show: boolean, message: string): { 
        type: ActionTypes.SET_SNACK_BAR, 
        show: boolean, 
        message: string 
    } =>
        ({ type: ActionTypes.SET_SNACK_BAR, show, message })

export type Action = ReturnType<typeof setShowInviteResidentDialog>
    | ReturnType<typeof setShowLoading>
    | ReturnType<typeof setSnackBar>