import React from 'react'
import moment from 'moment'
import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './BasicResidentInfoBox.css'
import DeleteResidentDialog from "./DeleteResidentDialog";
import Button from "@material-ui/core/Button";
import  {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Link,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import Colors from '../../../config/colors';


const CompletedChip = styled(Chip)({
  background: 'green',
  color: 'white',
})

const ResidentPaper = styled(Paper)({
  paddingTop: 10 * 2,
  paddingBottom: 10 * 2,
  paddingLeft: 10 * 2,
  paddingRight: 10 * 2,
})

const ResidentStatus = styled(Typography)({
  float: 'left',
  display: 'flex',
  alignItems: 'center',
  height: 'fit-content'
})

class BasicResidentInfoBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.name,
      email: props.email,
      moveDate: props.moveDate,
      completedTasks: props.completedTasks,
      numTasks: props.numTasks,
      resident: props.resident,
      deleteResident: false
    }
    this.handDeleteSubmitted = this.handDeleteSubmitted.bind(this);
    this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
    this.archiveRecord = this.archiveRecord.bind(this);
    this.saveAsPDF = this.saveAsPDF.bind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({
      name: props.name,
      email: props.email,
      moveDate: props.moveDate,
      completedTasks: props.completedTasks,
      numTasks: props.numTasks,
      resident: props.resident,
      archive: props.resident.archive ? 'unarchive' : 'archive',
    })
  }

  archiveRecord() {
    var id = this.props.resident.id
    var building = this.props.resident.apartment
    var archive = this.props.resident.archive ? false : true
    this.props.archiveRecord(id, building, archive)
  }

  handleDeleteClicked() {
    this.setState({
      deleteResident: true,
      deleteConfirm: undefined
    });
  }

  handleDeleteCancel() {
    this.setState({
      deleteResident: false,
    });
  }

  handDeleteSubmitted() {
    var id = this.state.resident.id
    var building = this.state.resident.apartment
    var inviteCode = this.state.resident.inviteCode
    this.props.deleteRecord(id, building, inviteCode)
    this.setState({
      deleteResident: false,
    })
  }
  dayOfWeek(timestamp) {
    var result = ''
    var day = new Date(timestamp).getDay()
    switch (day) {
      case 1: result = ' Monday,'; break;
      case 2: result = ' Tuesday,'; break;
      case 3: result = ' Wednesday,'; break;
      case 4: result = ' Thursday,'; break;
      case 5: result = ' Friday,'; break;
      case 6: result = ' Saturday,'; break;
      case 0: result = ' Sunday,'; break;
      default: result = ' invalid Date'; break;
    }
    return result
  }

  saveAsPDF() {
    var element = document.querySelector('#taskInfo')
    if (element !== null) {
      this.saveTaskAsPDF()
    }
    else {
      this.props.handleMsgToPDF()
    }
  }

  btnStatus(resident) {
    var colors = {
      backgroundColor: '#ff463d',
      color: '#fffffffa'
    }
    if (resident.numTasks && resident.numTasks === resident.completedTasks) {
      colors.backgroundColor = 'green'
      colors.color = 'white'
    }
    else if (resident.numTasks && resident.completedTasks > 0) {
      colors.backgroundColor = '#faff71'
      colors.color = '#000000cb'
    }
    return colors
  }

  renderStatus = resident => {
    const requiredMoveInTasks = resident && resident.tasks
      ? Object.values(resident.tasks)
        .filter(task => task.tags && task.tags.includes('post-move-in'))
        .length
      : 0;
    const requiredMoveInTasksCompleted = resident && resident.tasks
      ? Object.values(resident.tasks)
        .filter(task => task.tags && task.tags.includes('post-move-in') && task.completedDate > 0)
        .length
      : 0;
    return (
      <ResidentStatus>
        {'Status:\xa0'}
        {
          resident.inviteTempAccount
            ? <Chip label={'Invited'} />
            : (<span> {' Required '} <Chip
              label={`${resident.completedTasks === undefined ? 0 : resident.completedTasks}
                    \xa0/\xa0 
                    ${resident.numTasks}
                  `}
              style={this.btnStatus(resident)}
            />
            </span>)
        } { requiredMoveInTasks > 0 &&
          <span>
            {'\xa0Post Move-in\xa0'} {
              <Chip
                label={`${requiredMoveInTasksCompleted}\xa0/\xa0${requiredMoveInTasks}`}
              />
            }
          </span>

        }
      </ResidentStatus>
    )
  }

  render() {
    const {
      name = '', email = '', moveDate, numTasks, completedTasks,
      resident, archive, deleteResident
    } = this.state;

    const { tasks = [] } = this.props

    const PrintButton = () => {
      // Create Document Component
      const styles = StyleSheet.create({
        page: {
          display: 'flex',
          backgroundColor: 'white',
          padding: "20 0",
        },
        section: {
          display: "flex",
          padding: "0 20 20 20",
        },
        taskRow: {
          display: "flex",
          padding: 0,
          margin: 0,
          width: "100%"
        },
        taskRowContent: {
          padding: "10 20",
          verticalAlign: "top",
          display: "flex",
        },
        taskContainer: {
          verticalAlign: "top",
          display: "flex",
        },
        taskHeader: {
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          margin: 0,
        },
        taskTitle: {
          margin: "2 0 0 10",
          textAlign: "left",
          float: "left",
          clear: "left",
          fontSize: "12"
        },
        taskSubmission: {
          float: "left",
          clear: "left",
          padding: "5 20 0 20",
        },
        submissionField: {
          float: "left",
          clear: "left",
          padding: "0 0 0 20",
        },
        fieldText: {
          fontSize: "12"
        },
        divider: {
          display: "flex",
          width: "100%",
          height: "1px",
          backgroundColor: Colors.dividerGray
        },
        iconText: {
          verticalAlign: "top",
          display: "flex",
          height: 17,
          width: 17,
        },
        completedDate: {
          paddingLeft: "20px"
        },
      });

      var name = `${resident.firstName}_${resident.lastName}_MoveInTasks.pdf`
      var userInfo = "Resident Name: " + resident.lastName + " " + resident.firstName
        + '\nMove-in Date: ' + this.dayOfWeek(moveDate) + " " + moment(moveDate).format("MMM D, YYYY")
        + '\nStatus: ' + completedTasks + '/' + numTasks

      const renderFieldContent = field => {
        switch (field.input) {
          case "text": return `: ${field.value}`
          case "phonenumber": return `: ${field.value}`
          case "yesNo": return `: ${field.value}`
          case "none": return ''
          case "file": return field.value === ""
            ? <Text style={{ display: "inline" }}>
              : N/A
            </Text>
            : <Text style={{ display: "inline" }}>
              <Link href={field.value}>: File</Link>
            </Text>
          default: return `: ${field.value}`
        }
      }
      const renderFormField = field => (
        <Text style={styles.submissionField} key={field.id}>
          {'\n'}
          <Text style={styles.fieldText}>{`${field.prompt}`}</Text>
          <Text>{renderFieldContent(field)}</Text>
        </Text>
      )
      const taskDateString = (timestamp) => {
        var date = new Date(timestamp);
        var hr = date.getHours();
        var yr = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var min = date.getMinutes();
        var am_pm = hr >= 12 ? 'PM' : 'AM';
        if (hr > 12) { hr = hr - 12 }
        else if (hr === 0) { hr = 12 }
        if (min < 10) { min = '0' + min }
        switch (month) {
          case 1: month = "January";
            break;
          case 2: month = "February";
            break;
          case 3: month = "March";
            break;
          case 4: month = "April";
            break;
          case 5: month = "May";
            break;
          case 6: month = "June";
            break;
          case 7: month = "July";
            break;
          case 8: month = "August";
            break;
          case 9: month = "September";
            break;
          case 10: month = "October";
            break;
          case 11: month = "November";
            break;
          case 12: month = "December";
            break;
          default:
            break;
        }

        return <Text style={{ marginTop: '1px' }}>{
          `${month} ${day}, ${yr} at ${hr}:${min} ${am_pm}`
        }</Text>;
      }

      const MyDocument = () => (
        <Document id={name}>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>{userInfo}</Text>
            </View>
            <View style={styles.divider} />
            {tasks.map(task =>
              <View style={styles.taskRow} key={task.id} wrap={false}>
                <View style={styles.taskRowContent}>
                  <View style={styles.taskHeader}>
                    {task.completedDate > 0
                      ? <Image
                        src="https://firebasestorage.googleapis.com/v0/b/muvn-dev.appspot.com/o/appImages%2FScreen%20Shot%202020-12-30%20at%2010.10.04%20AM.png?alt=media&token=08b05a3f-c115-4938-ab35-52b531b38466"
                        style={styles.iconText}
                      />
                      : <Image
                        src="https://firebasestorage.googleapis.com/v0/b/muvn-dev.appspot.com/o/appImages%2FScreen%20Shot%202020-12-30%20at%2010.09.38%20AM.png?alt=media&token=5c7f46e6-8b4b-42d6-9c90-52e3e5a006d1"
                        style={styles.iconText}
                      />
                    }
                    <Text style={styles.taskTitle}>{task.title ? task.title : task.taskName}</Text>
                  </View>
                  {task.submissions && task.submissions.length > 0 &&
                    <View style={styles.taskSubmission}>
                      {task.submissions[task.submissions.length - 1]
                          .fields
                          .map((field) =>
                            <View style={styles.submissionField}>
                              <Text style={styles.fieldText}>
                                {`${field.prompt}`}
                                {renderFieldContent(field)}
                              </Text>
                            </View>
                          )
                      }
                      <Text style={styles.completedDate}>
                        {task.completeDate && task.completedDate !== 0 &&
                          `Completed ${taskDateString(task.completedDate)}`
                        }
                      </Text>
                    </View>
                  }
                </View>
                <View style={styles.divider} />
              </View>
            )}
          </Page>
        </Document >
      );

      return <PDFDownloadLink
        document={<MyDocument />}
        fileName={name}
        style={{
          textDecoration: "none",
          width: "100px",
          height: "43px",
          position: "inline-block"
        }}
      >
        {({ blob, url, loading, error }) => (loading
          ? ''
          : <Button
            variant="outlined"
            style={{
              display: "flex",
              height: "inherit",
              width: "inherit",
              alignItems: "center",
            }}>
            <i style={{ color: 'black' }} className="material-icons">print</i>
            <span style={{ float: 'right' }}>PRINT</span>
          </Button>
        )}
      </PDFDownloadLink>
    };
    return (
      <div className="basic-info-box" >
        <ResidentPaper elevation={0}>
          <Typography variant="h5" component="h3">
            {name}
            <span style={{ float: 'right', display: "flex" }}>
              <PrintButton style={{ float: "left" }} />
              <Button
                variant="outlined"
                className={'archiveButton'}
                onClick={this.archiveRecord}
                style={{
                  display: "flex",
                  width: "120px",
                  height: "43px",
                  alignItems: "center",
                  position: 'inline-block',
                  marginLeft: "10px"
                }}
              >
                <i style={{ color: 'black' }} className="material-icons">{archive}</i>
                <span style={{ float: 'right' }}>{archive === 'archive' ? 'ARCHIVE' : 'UNARCHIVE'}</span>
              </Button>
              {/* Delete Button will be taken out for now

                            {resident.inviteTempAccount===undefined
                                ?
                                <Button
                                    variant="outlined"
                                    className={'archiveButton'}
                                    onClick={this.archiveRecord}
                                    style={{position:'inline-block'}}
                                >
                                    <i style={{color:"black"}} className="material-icons">{archive}</i>
                                    <span style={{float:'right', paddingTop:'7px'}}>{archive==='archive'?'ARCHIVE':'UNARCHIVE'}</span>
                                </Button>
                                : <Button
                                    variant="outlined"
                                    className={'deleteButton'}
                                    onClick={this.handleDeleteClicked}
                                    style={{position:'inline-block'}}
                                >
                                    <i style={{color:"black"}} className="material-icons">delete</i>
                                    <span style={{float:'right', paddingTop:'7px'}}>DELETE</span>
                                </Button>
                            }

                            */}
            </span>
          </Typography>
          <Typography component='subtitle1'>
            Email: {email}
          </Typography>
          <div className="move-status">
            <Typography component='subtitle1'>
              Move-in Date:{this.dayOfWeek(moveDate)} {moment(moveDate).format("MMM D, YYYY")}
            </Typography>
            {this.renderStatus(resident)}
          </div>
        </ResidentPaper>
        <DeleteResidentDialog
          open={deleteResident}
          onDeleteCancel={this.handleDeleteCancel.bind(this)}
          onDeleteSubmitted={this.handDeleteSubmitted.bind(this)}
        >
        </DeleteResidentDialog>
      </div>
    );
  }
}

export default BasicResidentInfoBox;