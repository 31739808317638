import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withAuthentication } from '../../session';
import LoginPage from "../Login/LoginPage";
import TopNavBar from "./TopNavBar";
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const Navigation = (props) => {
  const dispatch = useDispatch();
  const handleSignOut = () => {
    props.firebase.doSignOut();
  };
  var uid = JSON.parse(localStorage.getItem('authUser'))
  return uid !== null
    ? <NavigationAuth onSignOut={handleSignOut} />
    : <NavigationNonAuth />
}

const NavigationAuth = (props) => {
  return (
    <AppBar position="static" color="default">
      <TopNavBar
        selectedTab={props.tab}
        onSignOut={props.onSignOut}
        onTabChange={props.onTabChange}
      />
    </AppBar>
  )
}

const NavigationNonAuth = (props) => {
  return (
    <AppBar position="static" color="default">
      <Redirect to='/' />
      <LoginPage />
    </AppBar>
  )
}

export default compose(
  withAuthentication,
  withRouter
)(Navigation);