import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import ResetPasswordDialog from "./ResetPasswordDialog";
import './LoginPage.css';
import { withAuthentication } from '../../session';
import * as routes from '../../constants/routes';
import Link from '@material-ui/core/Link';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';

const LoginPage = ({ history }) =>
    <div>
        <LoginForm history={history} />
    </div>

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    open: false,
    resetPasswordEmail: '',
    snackBar: false,
    snackBarMsg: '',
    redirect: false
};

class LoginFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
        this.resetPassword = this.resetPassword.bind(this);
        this.onSendClicked = this.onSendClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onResetEmailChange = this.onResetEmailChange.bind(this);
        this.handleClose = this.handleClose.bind(this)
    }
    componentWillReceiveProps(props) {
        this.setState({
            open: props.open,
            resetPasswordEmail: props.resetPasswordEmail,
            redirect: props.authUser && !props.authUser.isAnonymous
        });
    }

    onSubmit = (event) => {
        const { email, password } = this.state;
        const { history } = this.props;

        this.props.firebase.doLoginWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                history.push(routes.DASHBOARD);
            })
            .catch(error => {
                console.error(error)
                this.setState(byPropKey('error', error));
            });
        event.preventDefault();
    }

    resetPassword() {
        this.setState({
            open: true
        })
    }

    onCancelClicked() {
        this.setState({
            open: false
        })
    }

    onResetEmailChange(email) {
        this.setState({
            resetPasswordEmail: email
        })
    }
    onSendClicked() {
        this.props.firebase.doPasswordReset(this.state.resetPasswordEmail)
            .then(() => {
                this.setState({
                    open: false,
                    snackBar: true,
                    snackBarMsg: 'Email Sent'
                })
            })
            .catch(() => {
                this.setState({
                    open: false,
                    snackBar: true,
                    snackBarMsg: 'errrrrrrrrrrrrror'
                })
            })
    }

    handleClose() {
        this.setState({
            snackBar: false
        })
    }

    render() {
        const { email, password, error, redirect } = this.state;
        if (redirect) { return <Redirect to='/dashboard' /> }
        const isInvalid = password === '' || email === '';
        return (
            <div className={'mainWrap'}>
                <div className={'loginWrap'}>
                    <div className={'imgWrap'}>
                        <img src={require('./muvn_logo.png')} alt='IMG' />
                    </div>
                    <form onSubmit={this.onSubmit} className={'formWrap'}>
                        <span className='titleWrap'>Please Login</span>
                        <div className={'inputWrap'}>
                            <input
                                className={'input'}
                                value={email}
                                onChange={event => this.setState(byPropKey('email', event.target.value))}
                                type='text'
                                placeholder='Email Address'
                            />
                        </div>
                        <div className={'inputWrap'}>
                            <input
                                className={'input'}
                                value={password}
                                onChange={event => this.setState(byPropKey('password', event.target.value))}
                                type='password'
                                placeholder='Password'
                            />
                        </div>
                        <div className='forgetWrap'>
                            <span className={'textOne'}>Forgot &nbsp;</span>
                            <Link onClick={this.resetPassword}>
                                <a style={{ color: 'black' }}>Password ?</a>
                            </Link>
                            <ResetPasswordDialog
                                open={this.state.open}
                                onSendClicked={this.onSendClicked.bind(this)}
                                onResetPasswordEmailChange={this.onResetEmailChange.bind(this)}
                                onCancelClicked={this.onCancelClicked.bind(this)}
                            >
                            </ResetPasswordDialog>
                        </div>
                        <div className={'btnWrap'}>
                            <button 
                                className={'btn'} 
                                disabled={isInvalid} 
                                type='submit'
                            >
                                Sign In
                            </button>
                        </div>
                        <div className={'errorWrap'}>{error && 
                            <p>The email or password you entered is incorrect.</p>
                        }</div>
                    </form>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackBar}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message={<span>{this.state.snackBarMsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            style={{ padding: '2px' }}
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

const LoginForm = compose(
    withRouter,
    withAuthentication,
)(LoginFormBase)

export default LoginPage;

export { LoginForm };