import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ResidentActions } from '../../actions';
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import './ResidentInfo.css'
import Button from "@material-ui/core/Button";
import { withFirebase } from '../../firebase';
import { DatePicker } from "material-ui-pickers";
// import MaskedInput from 'react-text-mask'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: { main: "#36ba8c" }, // Purple and green play nicely together.
    secondary: { main: '#000000' }, // This is just green.A700 as hex.
  },
});

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: '20px',
    width: 'calc(100% - (2*20px))'
  },
  editButton: {
    position: 'inline-block',
    left: '85%',
    marginTop: '30px'
  },
  date: {
    margin: 5,
    width: 150
  },
  cssLabel: {
    '&$cssFocused': {
      color: "#36ba8c",
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: "#36ba8c",
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "#36ba8c",
    },
  },
  notchedOutline: {},
  datePicker: {
    selectColor: "red",
  },
};

class ResidentInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      isSaving: false,
      changes: {},
      ...props.resident,
    };
    this.handleEditClicked = this.handleEditClicked.bind(this);
    this.infoTextField = React.createRef();
  };

  componentWillReceiveProps(newProps) {
    const { isEditing } = this.state;
    // ignore update if editing
    if (isEditing) { return }
    this.setState({
      isEditing: false,
      isSaving: false,
      ...newProps.resident,
    });
  }

  handleEditClicked() {
    const { isEditing, changes } = this.state;
    if (isEditing) { // save 
      this.setState({ isSaving: true }, () => {
        const { firebase, residentId } = this.props;
        this.props.firebase.saveResidentDetailsChanges(residentId, changes)
          .then(
            this.setState({
              isEditing: false,
              changes: {}
            })
          ).catch(error => {
            // push an error notification
            this.setState({
              isEditing: true,
              changes: {}
            })
            console.error(error)
          });
      });
    } else { // edit resident info
      this.setState({
        isEditing: true,
      });
    }
  }

  onMoveDateChange(date) {
    this.setState({
      changes: {
        ...this.state.changes,
        moveDate: date.valueOf()
      }
    });
  }

  onPhoneNumberChange(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        phonenumber: event.target.value
      }
    })
  }

  onUnitChange(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        unit: event.target.value,
      }
    })
  }

  onMailboxChange(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        mailbox: event.target.value
      }
    })
  }

  onParkingSpaceChange(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        parkingSpace: event.target.value
      }
    })
  }

  onMailingAddressChange(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        mailingAddress: event.target.value
      }
    })
  }

  onAdditionalInfo(event) {
    this.setState({
      changes: {
        ...this.state.changes,
        additionalInfo: event.target.value
      }
    })
  }

  render() {
    const { classes } = this.props;
    const {
      isEditing, isSaving, changes,
      phonenumber, unit, mailbox,
      parkingSpace, mailingAddress, additionalInfo, moveDate
    } = this.state;
    return (
      <div className={'infoWrapper'}>
        <form className={classes.container}>
          <Button
            variant="outlined"
            className={classes.editButton}
            onClick={this.handleEditClicked}
            disabled={isSaving}
          >
            <i className="material-icons" style={{ color: 'black' }}>
              {isEditing ? "save" : "edit"}
            </i>
            {isEditing
              ? "SAVE"
              : "EDIT"
            }
          </Button>
          <MuiThemeProvider theme={theme}>
            <DatePicker
              className={classes.textField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  underline: classes.cssUnderline,
                },
              }}
              onlyCalendar
              margin="dense"
              id='date'
              label="Move-In Date"
              format="M/DD/YYYY"
              placeholder="mm/dd/yyyy"
              mask={value =>
                // handle clearing outside if value can be changed outside of the component
                value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
              }
              value={changes.moveDate ? changes.moveDate : moveDate}
              onChange={this.onMoveDateChange.bind(this)}
              disableOpenOnEnter
              variant="outlined"
              animateYearScrolling={false}
              disabled={!isEditing || isSaving}
            />
          </MuiThemeProvider>
          <TextField

            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            margin="dense"
            id="phoneNumber"
            label="Mobile Number"
            // placeholder="(###) ###-####"
            // format="(###) ###-####" mask=""
            // placeholder={this.props.phoneNumber}
            // mask="(###) ### ####" maskChar=" "
            // value={phoneNumber}
            // inputRef={phoneNumber}
            // maxLength="10"
            // pattern="[0-9]*"
            // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            // showMask 
            // guide={false}
            value={changes.phonenumber ? changes.phonenumber : phonenumber}
            onChange={this.onPhoneNumberChange.bind(this)}
            type='tel'
            fullWidth={true}
            variant="outlined"
            disabled={!isEditing || isSaving} />

          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            autoFocus
            margin="normal"
            id="unit"
            placeholder='Unit'
            value={changes.unit ? changes.unit : unit}
            onChange={this.onUnitChange.bind(this)}
            type="text"
            fullWidth={true}
            multiline
            label="Unit"
            variant="outlined"
            disabled={!isEditing || isSaving}
          />
          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            autoFocus
            margin="normal"
            id="mailbox"
            placeholder='Mailbox'
            label="Mailbox"
            value={changes.mailbox ? changes.mailbox : mailbox}
            onChange={this.onMailboxChange.bind(this)}
            type="text"
            fullWidth={true}
            multiline
            variant="outlined"
            disabled={!isEditing || isSaving}
          />
          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            autoFocus
            margin="normal"
            id="parkingSpace"
            placeholder='Parking Space'
            label='Parking Space'
            value={changes.parkingSpace ? changes.parkingSpace : parkingSpace}
            onChange={this.onParkingSpaceChange.bind(this)}
            type="text"
            fullWidth={true}
            multiline
            variant="outlined"
            disabled={!isEditing || isSaving}
          />
          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            autoFocus
            margin="normal"
            id="mailingAddress"
            placeholder='Mailing Address'
            label='Mailing Address'
            value={changes.mailingAddress ? changes.mailingAddress : mailingAddress}
            onChange={this.onMailingAddressChange.bind(this)}
            type="text"
            fullWidth={true}
            multiline
            variant="outlined"
            disabled={!isEditing || isSaving}
          />
          <TextField
            className={classes.textField}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
                underline: classes.cssUnderline,
              },
            }}
            autoFocus
            margin="normal"
            id="additionalInfo"
            placeholder='Additional Info'
            label='Additional Info'
            value={changes.additionalInfo ? changes.additionalInfo : additionalInfo}
            onChange={this.onAdditionalInfo.bind(this)}
            type="text"
            fullWidth={true}
            multiline
            variant="outlined"
            disabled={!isEditing || isSaving}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { residentId } = ownProps;
  return { resident: state.residentsState[residentId] };
}

const mapActionsToProps = dispatch => ({
  saveResidentInfo: (firebase, residentId, residentInfo) =>
    ResidentActions.updateResidentInfo(firebase, residentId, residentInfo),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapActionsToProps),
  withFirebase,
)(ResidentInfo);
