import React from 'react';
export default function loading() {
  return (
    <div style={{ display: "grid", height: "100%" }}>
      <img 
        src="muvn_logo.png"
        style={{
          maxWidth: "50%",
          paddingTop: "10%",
          maxHeight: "100vh",
          margin: "auto"
        }}
        alt="Loading..."
      />
      <div className="loader"></div>
    </div>
  )
}