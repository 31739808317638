import React from 'react'

import withAuthentication from './withAuthentication'
import { MessageContext } from './context'

const withFirebaseMessage = Component => {
    class FirebaseMessage extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                allMessages:''
            }
        }

        componentWillReceiveProps(props) {
            // check if user changed then setup new things
            if (this.listener !== undefined) {
                this.listener()
            }
            // set up listener for msg
            return this.props.firebase.conversationsDataRef()
                .then(messageDataRef => {
                    // change based on the changes of the snapshot
                    var allMessage ={}
                    if(messageDataRef!== undefined)
                    {
                        this.listener = messageDataRef.on('value',snapshot=> {
                            if(snapshot !== undefined)
                            {
                                Object.values(snapshot.val()).map((message)=>
                                    allMessage=Object.values( message)
                                )
                                //console.log('Recieved MSG Updates');
                            }
                            this.setState({
                                allMessages:allMessage
                            })
                            return allMessage

                        }, error => {
                            // TODO: HANDLE ERROR
                            console.error(error)
                        })
                    }
                })
                .catch(error => {
                    console.error('MSG Listener Error: ', error)
                    throw error;
                })
        }

        componentWillUnmount() {
            if (this.listener !== undefined) {
                this.listener()
            }
        }

        render() {
            const allMessages = this.state;
            return (
                <MessageContext.Provider value={allMessages}>
                    <Component {...this.props} messages={allMessages} />
                </MessageContext.Provider>
            );
        }
    };

    return withAuthentication(FirebaseMessage);
}

export default withFirebaseMessage;