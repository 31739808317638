export default {
    root: {
        display: 'flex',
        height: 'fit-content',
        width: '100%',
        flexDirection: 'column',
    }, 
    poster: {
        display: 'flex',
        height: 'fit-content',
        width: 'calc(100% - 20px)',
        margin: '10px 10px 5px 10px',
    },
    postInfoText: {
        display: 'block',
        flexDirection: 'row',
        width: 'calc(100% - (80px))',
    },
    posterImage: {
        height: '60px',
        width: '60px',
        marginRight: '10px',
        borderRadius: '50%',
        float: 'left',
    },
    moreOptionsButton: {
        float: 'right',
        height: '35px',
        width: '20px',
        color: 'darkslategray',
        display: 'flex',
    },
    postImage: {
        height: 'fit-content',
        maxHeight: '100%',
    },
    moreOptionsImage: {
    },
    editPost: {
        width: 'calc(100% - 20px)',
        margin:'5px 10px 10px 10px'
    },
    editPostTextField: {
        width: '100%',
        minHeight: '40px',
    },
    saveButton: {
        color: 'blue',
        float: 'right',
        height: '30px',
        width: '60px',
    },
    cancelButton: {
        color: 'darkslategray',
        float: 'right',
        height: '30px',
        width: '60px',
    },
    postText: {
        margin: '5px 10px 10px 10px'
    },
    loadingView: {
        height: 'calc(100% - 27px)',
        width: 'calc(100% - 34px)',
        backgroundColor: '#ffffffaa',
        top: 0,
        left: 0,
        margin: '11px 16px 17px 17px',
        position: 'absolute',
    },
    loadingSpinner: {
        color: '#36ba8c',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
};
