import React, { Component } from 'react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { compose } from 'recompose';
import ResidentsDashboardPage from './components/ResidentsDashboard/ResidentsDashboard'
import ApartmentSetupPage from './components/ApartmentSetUpPage/index'
import Navigation from './components/Navigation/Navigation';
import GalleryPage from './components/GalleryPage';
import LoginPage from './components/Login/LoginPage'
import CommunityBoard from './components/CommunityBoard';
import { withNotifications } from './session'
import * as routes from './constants/routes'
import { BrowserRouter as Router, Route, } from 'react-router-dom';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authUser: props.authUser }
  }

  componentDidMount() {
    document.title = "MuvnDay for Apartments";
  }

  render() {
    const { authUser } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <div className="app">
            <Navigation className="nav" authUser={authUser} />
            <div className="page">
              <Route exact path={routes.HOME} component={LoginPage} />
              <Route exact path={routes.LOGIN} component={LoginPage} />
              <Route exact path={routes.DASHBOARD} component={ResidentsDashboardPage} />
              <Route exact path={routes.COMMUNITY_BOARD} component={CommunityBoard} />
              <Route exact path={routes.APARTMENT_SETUP} component={ApartmentSetupPage} />
            </div>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    );
  }
}

export default compose(
  withNotifications,
)(App);
