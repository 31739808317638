import Firebase from '../firebase'

/**
 * 
 * @param {Firebase} firebase 
 * @param {string} postText 
 * @param {[string]} tags 
 * @param {{sourceType: string, url: string}} media 
 */
export const createPost = (firebase, postText, tags, media) => {
    return firebase.createPost(postText, tags, media);
};

/**
 * 
 * @param {Firebase} firebase 
 */
export const getAllPosts = (firebase) => {
    return firebase.getAllPosts();
}

/** uploadImage
 * 
 * @param {*} firebase 
 * @param {*} imageFile 
 */
export const uploadImage = (firebase, imageFile) => {
    return firebase.uploadImage(imageFile);
}

/** editPost
 *
 * @param {Firebase} firebase 
 * @param {string} id 
 * @param {string} text 
 * @param {[string]} tags 
 * @param {{sourceType: string, url: string}} media 
 */
export const editPost = (firebase, id, text, tags, media) => {
    return firebase.editPost(id, text, tags, media);
}

/**
 * deletePost
 * @param {Firebase} firebase 
 * @param {string} id 
 */
export const deletePost = (firebase, id) => {
    return firebase.deletePost(id);
}