import React, { Suspense } from 'react'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import BasicResidentInfoBox from '../BasicResidentInfoBox/BasicResidentInfoBox';
import ResidentTaskPage from '../../ResidentInfoPage/ResidentTaskPage';
import ResidentInfo from '../../ResidentInfoPage/ResidentInfo';
import ResidentMessagesPage from '../ResidentMessagesPage/ResidentMessagesPage';
import { Tabs, Tab, createMuiTheme, MuiThemeProvider, } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './ResidentDashboard.css'
import Badge from "@material-ui/core/Badge";
import { withFirebase } from '../../../firebase';
import { ResidentActions } from '../../../actions/actions';
import LoadingView from '../../LoadingView';
import Colors from '../../../config/colors';

// Lazy Loading
const InternalTasksPage = React.lazy(() => import('../InternalTasksPage'));

const theme = createMuiTheme({
  palette: {
    primary: { main: "#36ba8c" },
    secondary: { main: '#0181ff' },
  },
});

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#00000000',
  },
  tabRoot: {
    textTransform: 'initial',
  }
})


const mapStateToProps = (state, ownProps) => {
  const { residentId } = ownProps;
  if (residentId) {
    return {
      resident: state.residentsState[residentId],
      apartment: state.apartmentState.apartment
    }
  }
  return {
    resident: residentId ? state.residentsState[residentId] : {},
    apartment: state.apartmentState.apartment
  }
}

const mapDispatchToProps = dispatch => ({
  updateResident: (residentId, update) =>
    ResidentActions.updateResident(residentId, update)
})

class ResidentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      tab: props.tab,
      resident: props.resident,
      tasks: {},
    };
    this.readUpdate = this.readUpdate.bind(this);
    this.archiveRecord = this.archiveRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
  }

  componentWillReceiveProps(newProps) {
    // if this is a resident then fetch most recent data
    if (newProps.residentId && (this.props.residentId !== undefined || (
      newProps.residentId !== this.props.residentId
    ))) {
      if (this.tasksListener) { this.tasksListener() }
      // fetch resident from firestore
      newProps.firebase.getResident(newProps.residentId)
        .catch(console.error);
      ;
    }
    this.setState({
      tabs: this.Tabs(),
      tab: newProps.tab,
      resident: newProps.resident,
    });
  }

  Tabs = (tab) => {
    const { classes, resident, apartment } = this.props;
    if (!resident) { return "" }
    return (
      <Tabs
        value={tab}
        fullWidth
        onChange={this.onTabSelected}
        indicatorColor={"primary"}
      >
        <Tab
          label="Move-In Tasks"
          classes={{ root: classes.tabRoot }}
        />
        <Tab
          label="Resident Info"
          classes={{ root: classes.tabRoot }}
        />
        <Tab
          label="Internal Tasks"
          classes={{ root: classes.tabRoot }}
        />
        <Tab
          label={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a>
              <Badge
                color="secondary"
                variant="standard"
                invisible={!resident.hasNewMessage}
                style={{ left: '-5%' }}
              />
              {"Messages"}
            </a>
          }
          classes={{ root: classes.tabRoot }}
          disabled={
            (resident.numTasks === undefined)
            || !(apartment && apartment.messageUsEnabled)
          }
          onClick={this.readUpdate}
        />
      </Tabs>
    )
  }

  onTabSelected = (event, value) => {
    if (this.props.handleTabChange) {
      this.props.handleTabChange(value)
    } else {
      this.setState({
        tab: value,
      });
    }
  }

  sortTask(unsorted) {
    let tasks = unsorted.sort((first, last) => first.dueDate < last.dueDate);
    return tasks;
  }

  messageFunctionEnable(residentApartment) {
    //return true if message function enabled on the back-end
    return this.props.firebase.messageFunctionEnable(residentApartment);
  }

  deleteRecord(id, building, inviteCode) {
    //delete record
    this.props.firebase.deleteRecord(id, building, inviteCode);
  }

  archiveRecord(id, building, archive) {
    this.props.firebase.archiveRecord(id, building, archive)
      .then(result => {
        this.props.updateResident(id, { archive: archive })
      });
  }

  readUpdate() {
    const { firebase } = this.props;
    const { resident } = this.state;
    firebase.readUpdate(resident.id, resident.knownPlace);
  }

  handleMsgToPDF() { this.setState({ doPrint: 1 }) }

  handleEndPDF() { this.setState({ doPrint: 0 }) }

  render() {
    const { resident } = this.props;
    const { tab } = this.props;
    const tasks = resident !== undefined
      && resident.tasks !== null
      && resident.tasks !== undefined
      ? Object.values(resident.tasks)
      : []
      ;
    const sortedTasks = this.sortTask(tasks);
    return (
      <div className="resident-dashboard">
        {resident && resident.id &&
          <Paper className="resident-dashboard-paper" elevation={3}>
            <BasicResidentInfoBox
              resident={resident}
              name={`${resident.firstName ? resident.firstName : 'No Resident'} ${resident.lastName ? resident.lastName : ''}`}
              email={resident.email}
              moveDate={resident.moveDate}
              completedTasks={resident.completedTasks}
              numTasks={resident.numTasks}
              tasks={tasks}
              inviteTemp={resident.inviteTempAccount}
              deleteRecord={this.deleteRecord}
              archiveRecord={this.archiveRecord}
              handleMsgToPDF={this.handleMsgToPDF.bind(this)}
            />
            <Divider variant="middle" style={{ height: "2px" }} />
            <MuiThemeProvider theme={theme}>
              {this.Tabs(tab)}
            </MuiThemeProvider>
            <Suspense fallback={<LoadingView />}>
              {(() => {
                switch (tab) {
                  case 0: return (
                    <ResidentTaskPage
                      className="resident-page-info"
                      tasks={sortedTasks}
                      inviteCode={resident.inviteCode}
                      resident={resident}
                      inviteSendDate={resident.emailSendDate}
                    />
                  );
                  case 1: return <ResidentInfo residentId={resident.id} />
                  case 2: return <InternalTasksPage residentId={resident.id} />
                  case 3: return <ResidentMessagesPage
                    doPrint={this.state.doPrint}
                    startPrint={this.handleEndPDF.bind(this)}
                    className="resident-page-message"
                    resident={resident}
                  />
                  default: break;
                }
              })()}
            </Suspense>
          </Paper>
        }
      </div>
    );
  }
}

ResidentDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(ResidentDashboard);