import { Action, ActionTypes } from "./actions"

export const INITIAL_STATE = {
    // show modal views
    showInviteResidentDialog: false,
    showLoading: false,
    showSnackBar: false,
    showMsgBar: false,

    // display text
    snackBarMessage: "",
    msgBarMessage: ""
}

export type State = typeof INITIAL_STATE

const ResidentsDashboardReducer = (state: State = INITIAL_STATE, action: Action): State => {
    switch (action.type) {
        case ActionTypes.SET_SHOW_INVITE_RESIDENT_DIALOG:
            return { ...state, showInviteResidentDialog: action.show }
        case ActionTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.show }
        case ActionTypes.SET_SNACK_BAR:
            return { ...state, showSnackBar: action.show, snackBarMessage: action.message }
    }
    return state;
};

export default ResidentsDashboardReducer;