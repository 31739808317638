import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebaseMessage } from '../../../session';
import './ResidentMessagesPage.css'
import moment from "moment";
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";

var messageContainer = '';
var messageClass = '';
var dateContainer = '';
class ResidentMessagesPage extends React.Component {
  constructor(props) {
    super(props);

    const { resident, apartment } = this.props;
    this.updateMessage = this.updateMessage.bind(this);
    this.readUpdate = this.readUpdate.bind(this);
    this.submitMessage = this.submitMessage.bind(this);
    this.checkEmptyMsg = this.checkEmptyMsg.bind(this);
    this.checkCSS = this.checkCSS.bind(this);
    this.msgDate = this.msgDate.bind(this);
    this.printAsPDF = this.printAsPDF.bind(this);
    this.state = {
      resident: props.resident,
      webMessage: '',
      allMessages: [],
      messageID: '',
      user: '',
    };
    this.props.firebase.getConversation(resident.id, apartment.apartmentId)
      .then(conversation => {
        console.log('The conversation was retrieved', conversation);
        // var scroll = document.getElementById('col-content');
        var test = this.sortMessageArray(conversation['messages']);
        this.setState({
          allMessages: test,
          conversationId: conversation.id,
        })
        // scroll.scrollTop = scroll.scrollHeight;
      });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.doPrint == 1) {
      // handle print
      this.printAsPDF()
      // change back to 0
      this.props.startPrint()
    }
    const { resident, apartment } = this.props;
    this.props.firebase.getConversation(resident.id, apartment.apartmentId)
      .then(conversation => {
        console.log('The conversation was retrieved', conversation);
        var scroll = document.getElementById('col-content');
        var test = this.sortMessageArray(conversation['messages']);
        this.setState({
          allMessages: test,
          conversationId: conversation.id,
        })
        scroll.scrollTop = scroll.scrollHeight;
      });
  }

  componentDidMount() {
    return this.props.firebase.user()
      .then(user => {
        this.setState({
          user: user['apartmentName']
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  updateMessage(event) {
    this.setState({
      webMessage: event.target.value
    })
  }

  submitMessage(event) {
    var conversationId = this.state.conversationId;
    var name = this.props.apartment.apartmentName;
    var body = this.state.webMessage;
    var uid = this.props.apartment.apartmentId;
    this.props.firebase.writeMessages(conversationId, name, body, uid);
    document.getElementById('msgInput').value = '';
  }

  checkEmptyMsg(event) {
    if ((event.which === 13) && (!event.shiftKey)) {
      event.preventDefault();
      if (event.target.value.trim() !== '') {
        document.getElementById('msgSubmit').click();
      }
    }
    this.readUpdate();
  }

  sortMessageArray(allMessageObj) {
    if ((allMessageObj !== undefined) && (allMessageObj !== null)) {
      var allMessageArray = Object.values(allMessageObj);
      allMessageArray.sort(function (a, b) {
        return a.createdAt - b.createdAt;
      });
      return allMessageArray;
    }
  }

  msgDate(timestamp, pdfCounter) {
    var date = new Date(timestamp);
    var am_pm = date.getHours() >= 12 ? 'PM' : 'AM';
    var hr = date.getHours() % 12;
    var yr = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var min = date.getMinutes();
    var sec = date.getSeconds();
    hr = hr ? hr : 12;
    if (min === 0) {
      min = '00';
    }
    else if (min < 10) {
      min = '0' + min;
    }
    if (pdfCounter === true) {
      return month + '/' + day + '/' + yr + ' ' + hr + ':' + min + ':' + sec + ' ' + am_pm;
    } else {
      return <p style={{ marginTop: '1px', fontSize: 'small' }}>
        {month + '/' + day + '/' + yr + ' ' + hr + ':' + min + ' ' + am_pm}
      </p>;
    }
  }

  checkCSS(messageInfo) {
    if (messageInfo.author.name === this.props.apartment.apartmentName) {
      messageContainer = 'col-message-sent';
      messageClass = 'message-sent';
      dateContainer = 'date-sent';
    } else {
      messageContainer = 'col-message-received';
      messageClass = 'message-received';
      dateContainer = 'date-received';
    }
  }

  readUpdate() {
    const { resident } = this.props;
    this.props.firebase.readUpdate(resident.id, resident.knownPlace);
  }

  printAsPDF() {
    const { resident } = this.props;
    var name = resident.firstName + '_' + resident.lastName + "_Messages.pdf";
    var doc = new jsPDF('p', 'mm', 'a4', true);
    var y = 20;
    if (this.state.allMessages !== undefined) {
      this.state.allMessages.map((message) => {
        var messageBody = function (m) {
          var result = '';
          while (m.length > 0) {
            result += m.substring(0, 70) + '\n';
            m = m.substring(70);
          }
          return result;
        };
        doc.setFontSize(12);
        doc.setFontStyle("bold");
        doc.text(20, y, message.author.name);
        y = y + 7;
        doc.setFontStyle('courier');
        doc.setFontSize(8);
        doc.text(20, y, this.msgDate(message.createdAt, true));
        y = y + 7;
        doc.setFontSize(12);
        doc.setFontStyle("normal");
        doc.text(25, y, messageBody(message.body));
        y = y + messageBody(message.body).split(/\r\n|\r|\n/).length * 5 + 5;
        if (y >= 220) {
          doc.addPage();
          y = 20;
        }
      });
      doc.save(name);
    } else {
      window.alert('No Message Available');
    }
  }

  render() {
    const { allMessages } = this.state;
    const { updateMessage, checkEmptyMsg } = this;
    return (
      <div className='wrapper' onMouseOver={this.readUpdate}>
        <div className='col' id='message-Content'>
          <div id='col-content'>
            <section className='message'>
              {
                this.state.allMessages !== undefined ?
                  Object.values(allMessages).map((message) =>
                    <div>
                      <div className='grid-message'>
                        {this.checkCSS(message)}
                        <div className={messageContainer}>
                          <div className={messageClass} style={{ whiteSpace: 'pre-wrap' }}>
                            <p>{message.body}</p>
                          </div>
                        </div>
                        <div style={{ display: "block" }}>
                          <div className={dateContainer}>{this.msgDate(message.createdAt)}</div>
                        </div>
                      </div>
                    </div>) : console.log('error')
              }
            </section>
          </div>
          <div className='col-input'>
            <div className="compose">
              <textarea
                placeholder='Type a message...'
                type='text'
                onChange={updateMessage}
                id='msgInput'
                onKeyPress={checkEmptyMsg} />
              <button style={{ display: 'none' }} onClick={this.submitMessage} id='msgSubmit'>Submit Message</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  apartment: state.apartmentState.apartment,
});


export default compose(
  withFirebaseMessage,
  connect(mapStateToProps)
)(ResidentMessagesPage);