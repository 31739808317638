import Firebase, { firestore } from './firebase';
import Auth from './auth';
import FirebaseContext, { withFirebase } from './context';

export default Firebase;   

export {
  FirebaseContext,
  withFirebase,
  Auth,
}; 