import Firebase from './firebase'

// Auth callbacks

class Auth {
    // Create Account
    doCreateuserWithEmailAndPassword = (email, password) => 
        Firebase.createUserAndRetrieveDataWithEmailAndPassword(email,password);

    // Sign In
    doLoginWithEmailAndPassword = (email, password) =>
        Firebase.signInWithEmailAndPassword(email, password);

    // Sign out
    doSignOut = () =>
        Firebase.signOut();

    // Password Reset
    doPasswordReset = (email) =>
        Firebase.sendPasswordResetEmail(email);

    // Password Change
    doPasswordUpdate = (password) =>
        Firebase.currentUser.updatePassword(password);

    /* User */
    uid = () => {
        return Firebase.currentUser !== null
            ? Firebase.uid 
            : undefined;
    }
}

export default Auth;