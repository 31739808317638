import React from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


class DeleteResidentDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open:false,
            onDeleteChange:props.onDeleteChange
        };
        this.onCancelDelete=this.onCancelDelete.bind(this)
        this.onDeleteSubmitted=this.onDeleteSubmitted.bind(this)
    };

    componentWillReceiveProps(props) {
        this.setState({
            open:props.open,
        })
    }

    onCancelDelete() {
        this.props.onDeleteCancel()
    }

    onDeleteSubmitted(e) {
        this.props.onDeleteSubmitted();
    }

    render() {
        var open=this.state.open
        return(
            <div>
                <Dialog open={open} fullWidth	>
                    <DialogTitle id="form-dialog-title" align="center" >Are you sure you want to delete this?</DialogTitle>
                    <DialogContent>
                        <DialogContentText align="center"></DialogContentText>
                        <div style={{position:'absolute',top:'60%',left:'38%'}}>
                        <Button onClick={this.onCancelDelete}>
                            <a style={{color:"black"}}>Cancel</a>
                        </Button>
                        <Button onClick={this.onDeleteSubmitted}>
                            <a style={{color:"black"}}>Delete</a>
                        </Button>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
};

export default DeleteResidentDialog