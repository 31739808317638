import { ResidentActions } from '../actions'

const INITIAL_STATE = {};

const setResidents = (state, residents) => ({
    ...state,
    ...residents,
});

const setResident = (state, resident) => ({
    ...state,
    [resident.id] : resident,
});

const removeResident = (state, residentId) => {
    const { [residentId]: removed, ...residents} = state;
    return residents;
}

const removeAllResidents = (state) => ({});

const updateResident = (state, residentId, update) => ({
    ...state,
    [residentId] : {
        ...state[residentId],
        ...update,
        tasks : {
            ...state[residentId].tasks,
            ...update.tasks
        }
    }
});

const residentsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ResidentActions.SET_RESIDENTS:
            return setResidents(state, action.residents);
        case ResidentActions.SET_RESIDENT:
            return setResident(state, action.resident);
        case ResidentActions.UPDATE_RESIDENT:
            return updateResident(state, action.residentId, action.update);
        case ResidentActions.REMOVE_RESIDENT:
            return removeResident(state, action.residentId);
        case ResidentActions.REMOVE_RESIDENTS:
            return INITIAL_STATE;
        default: return state;
    }
};

export default residentsReducer;