import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withAuthentication } from '../../session';
import SideMenu from './SideMenu/SideMenu';
// import { Grid } from '@material-ui/core';
import useReduxModule from '../../useReduxModule';
import { connect } from 'react-redux';
import saga from './sagas'
import { INITIAL_STATE } from './reducer';
import { SideMenuReducer } from './reducer';
import { compose } from 'recompose';
import LoadingView from '../LoadingView'

// Lazy Loading sub components
const Faqs = React.lazy(() => import("./FAQs"));
const GeneralInfo = React.lazy(() => import("./GeneralInfo/GeneralInfo"));
const Amenities = React.lazy(() => import("./Amenities/Amenities"));
const Team = React.lazy(() => import("./Team"));
const Photos = React.lazy(() => import("./Photos/Photos"));
const ImportantPhoneNumbers = React.lazy(() => import("./ImportantPhoneNumbers/Display"));
const EmailSettings = React.lazy(() => import("./EmailSettings/Display"));
const Tasks = React.lazy(() => import("./Tasks"));
const PostMoveInTasks = React.lazy(() => import("./PostMoveInTasks"));
const InternalTasks = React.lazy(() => import("./InternalTasks"));

const renderSelectedPage = (selectedMenuItem) => {
    switch (selectedMenuItem) {
        case "generalInfo": return <GeneralInfo />
        case "faqs": return <Faqs />
        case "team": return <Team />
        case "amenities": return <Amenities />
        case "photos": return <Photos />
        case "importantPhoneNumbers": return <ImportantPhoneNumbers />
        case "emailSettings": return <EmailSettings />
        case "requiredMoveInTasks": return <Tasks />
        case "postMoveInTasks": return <PostMoveInTasks />
        case "internalTasks": return <InternalTasks />
    }
    return '';
}

const styles = {
    root: {
        display: 'flex',
        backgroundColor: 'white',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative'
    },
    sideMenu: {
        height: '100%',
        width: 'fit-content',
        maxWidth: '250px',
        float: "left"
    },
    settingPage: {
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        margin: "0",
    },
    settingPageContent: {
        height: 'fit-content',
        width: "100%",
        marginLeft: '25px',
        marginRight: '25px',
    }
}

function ApartmentSetupPage (props) {
    const { selectedMenuItem, classes } = props;
    useReduxModule('SideMenu', SideMenuReducer, saga, function(){})
    return (
        <div className={classes.root} container spacing={0}>
            <div className={classes.sideMenu} >
                <SideMenu />
            </div>
            <div className={classes.settingPage}>
                <div className={classes.settingPageContent}>
                    <React.Suspense fallback={<LoadingView />}>
                        { renderSelectedPage(selectedMenuItem) }
                    </React.Suspense>
                </div>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state) => {
    const { SideMenu = INITIAL_STATE } = state;
    return SideMenu;
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(ApartmentSetupPage);