import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/styles';
import { 
    Button,
    createMuiTheme, List, ListItem,
    TextField, Divider, Card,
} from '@material-ui/core';
import { withFirebase } from '../../firebase';
import Post, { OwnedPost } from '../Post';
import CreatePost from '../CreatePost';
import './communityBoard.css';
import { PostActions } from '../../actions';

const theme = createMuiTheme({
    palette: {
        primary: { main: "#36ba8c" },
        secondary: { main: '#0181ff' },
    },
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        background: '#E1E1E1',
    },
    createPost: {
        height : '100%',
        width : '100%',
    },
    post: {
        width: "100%",
        margin: "10px 5px 10px 10px",
        clear: "both",
        display: "block",
    },
    postComment: {
        margin: '10px',
        width: "calc(100% - 20px)%",
        minHeight: "40px",
        display: "flex",
    },
    postCommentButton: {
        backgroundColor: '#36ba8c',
        borderRadius: theme.spacing.unit * 1,
        height: "40px",
        width: "100px",
        marginLeft : "5px",
    }
});

const PostPaper = styled(Card)({
    marginBottom: "5px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden',
})


const PostListItem = styled(ListItem)({
    maxWidth: "700px",
    width: "100%",
    display: "table",
    margin: "0 auto",
})

class CommunityBoard extends React.Component {
    constructor(props) {
        super(props)
        this.state = { posts : [] }
        this.createPostTextRef = React.createRef();
        this.getPost = this.getPost.bind(this)
    }

    componentDidMount() {
        // set listener for posts
        this.onCreatePostTextChange = this.onCreatePostTextChange.bind(this)
        this.handlePostCreated = this.handlePostCreated.bind(this)
        if (this.props.apartment && this.props.apartment.apartmentId && this.props.firebase) {
            this.getPosts(this.props.apartment);
        }
    }

    componentWillReceiveProps(newProps) {
        const { apartment, firebase } = newProps;
        if (newProps.apartment && firebase && 
            apartment.apartmentId !== this.props.apartment.apartmentId
        ) { this.getPosts(newProps.apartment) }
    }

    getPost(postData) {
        const { apartment } = this.props;
        const date = moment(postData.createdAt)
        const lapse = moment.duration(moment().diff(date))
        var time = ''
        if (lapse.asDays() > 1) { time = date.format("MMM D [at] LT") }
        else if (lapse.asHours() > 1) {
            const hour = Math.floor(lapse.asHours())
            time = `${hour} hr${hour !== 1 ? 's' : ''}`
        } else if (lapse.asMinutes() > 1) {
            const min = Math.floor(lapse.asMinutes())
            time = `${min} min${min !== 1 ? 's' : ''}`
        } else {
            const sec = Math.floor(lapse.asSeconds())
            time = `${sec} sec${sec !== 1 ? 's' : ''}`
        }
        return {
            id: postData.id,
            poster : apartment.apartmentName,
            date  : time,
            text : postData.text,
            media : postData.media  
        }
    }

    getPosts( apartment ) {
        const { firebase } = this.props;
        return PostActions.getAllPosts(firebase, 'communityBoard')
        .then(posts => {
            this.setState({
                posts : posts.map(this.getPost)
            })
        });
    }

    componentWillUnmount() {
        // remove listener
    }

    onCreatePostTextChange(event) {
        this.setState({
            createPost : {
                ...this.state.createPost,
                postText : event.value
            }
        })
    }

    handlePostCreated() {
        this.getPosts(this.props.apartment);
    }

    handlePostUpdate = () => {
        this.getPosts(this.props.apartment);
    }

    render() { 
        const { posts } = this.state;
        const { classes, apartment } = this.props;
        return (
            // create post
            <List className={classes.root}>
                <PostListItem>
                    <PostPaper elevation={3}>
                        <CreatePost 
                            className={classes.createPost} 
                            onPostCreated={this.handlePostCreated}
                        />
                    </PostPaper>
                </PostListItem> 
                { posts.map((post) => 
                    <PostListItem alignItems="center">
                        <PostPaper elevation={4}>
                            <OwnedPost 
                                id={post.id}
                                poster={post.poster}
                                date={post.date}
                                text={post.text}
                                media={post.media}
                                thumbnail={apartment.thumbnailURL}
                                editable={false}
                                onUpdate={this.handlePostUpdate}
                            />
                            {/* <Divider variant="middle" /> */}
                            {/* <div className={classes.postComment}>
                                <TextField 
                                    placeholder="Write a comment..."
                                    align="center"
                                    fullWidth
                                    multiline
                                />
                                <Button className={classes.postCommentButton} variant='containted'>
                                    <a style={{color:'white'}}>
                                        COMMENT
                                    </a>
                                </Button>
                            </div> */}
                        </PostPaper>
                    </PostListItem>
                )}
            </List>
        )
    }
} 

const stateToProps = (state) => {
    return { apartment: state.apartmentState.apartment }
}

export default compose(
    connect(stateToProps),
    withFirebase,
    withStyles(styles(theme))
)(CommunityBoard);