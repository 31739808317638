import React from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './LoginPage.css';
import TextField from '@material-ui/core/TextField';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";


const theme = createMuiTheme({
    palette: {
        primary: { main: "#36ba8c" },
        secondary: { main: '#ffffff' },
    },
});
class ResetPasswordDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open:props.open,
            snackBar:false,
            snackBarMsg:''
        };
        this.onCancelClicked=this.onCancelClicked.bind(this);
        this.onSendClicked=this.onSendClicked.bind(this);
        this.onEmailChange=this.onEmailChange.bind(this);
    };

    componentWillReceiveProps(props) {
        this.setState({
            open:props.open,
            snackBar:props.snackBar,
            email:''
        })
    }

    onCancelClicked() {
        this.props.onCancelClicked()
    }

    onSendClicked() {
        this.props.onSendClicked();
    }

    onEmailChange(e) {
        this.props.onResetPasswordEmailChange(e.target.value);
    }

    render() {
        var open=this.state.open;
        return(
            <div>
                <MuiThemeProvider theme={theme}>
                <Dialog open={open} fullWidth>
                    <DialogTitle>
                        <h3 style={{textAlign:'center'}}>FORGOT YOUR PASSWORD?</h3>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText align="center"></DialogContentText>
                        <div style={{textAlign:'center'}}>
                            Not a big deal! We can email you a link to reset your password.
                            <TextField
                                label="Email"
                                type="email"
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                                fullWidth={true}
                                onChange={this.onEmailChange}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.onCancelClicked}>
                            CANCEL
                        </Button>
                        <Button color="secondary" style={{backgroundColor:'#36ba8c'}} onClick={this.onSendClicked}>
                            SEND
                        </Button>
                    </DialogActions>
                </Dialog>
                </MuiThemeProvider>
            </div>
        )
    }
};

export default ResetPasswordDialog