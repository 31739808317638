// libraries
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

// redux
import { store } from '../../index';
import ResidentsDashboardReducer, { INITIAL_STATE } from './reducers'
import { setShowInviteResidentDialog, setShowLoading, setSnackBar } from './actions';

import { RESIDENTS_DASHBOARD } from './types'

// sub modules
import { ResidentActions } from '../../actions';
import ResidentsList from './ResidentsList/ResidentsList';
import ResidentDashboard from './ResidentDasboard/ResidentDashboard';
import InviteResidentDialog from './InviteResidentDialog/InviteResidentDialog';
import Colors from '../../config/colors';
import './ResidentsDashboard.css';
import { withAuthentication } from '../../session';
var autoSelectCounter = 0; // select the first resident when user login

const styles = theme => ({
  newResident: {
    background: Colors.primary,
  },
  root: {

  },
  tabRoot: {
    backgroundColor: '#36ba8c',
    borderRadius: theme.spacing.unit * 1,
  },
  residentsList: {
    height: '100%',
    width: 'auto',
  }
});

class ResidentsDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      residents: [],
      inviteResident: false,
      invite: {},
      loading: false,
      snackBar: false,
      snackBarMessage: '',
      msgBar: false,
      msgBarMessage: '',
      direction: {
        moveDate: 'asc',
        firstName: 'asc',
        status: 'asc',
      },
      icons: 'arrow_upward',
      sortCounter: true,
      showArchive: false,
      tab: 0,
    };

    this.handleSelectResident = this.handleSelectResident.bind(this);
    this.getResident = this.getResident.bind(this);
    this.onInviteResidentClicked = this.onInviteResidentClicked.bind(this);
    this.handleInviteSubmitted = this.handleInviteSubmitted.bind(this);
    this.showAllArchive = this.showAllArchive.bind(this);
    this.hideAllArchive = this.hideAllArchive.bind(this);
    this.arrayToObject = this.arrayToObject.bind(this);
    this.handleViewMessageClicked = this.handleViewMessageClicked.bind(this);
  }

  componentDidMount() {
    this.props.firebase.getToken();
    store.injectReducer(RESIDENTS_DASHBOARD, ResidentsDashboardReducer);
  }

  componentWillReceiveProps(newProps) {
    const { apartment, firebase } = this.props;
    if (apartment && newProps.apartment && firebase &&
      (apartment.apartmentId !== newProps.apartment.apartmentId
        || this.residentsListener === undefined
      )
    ) {
      if (this.residentsListener) { this.residentsListener() }
      this.props.setResidentsListener(firebase).then(listener => {
        this.residentsListener = listener;
        this.setState({
          direction: {
            moveDate: 'desc', firstName: 'asc', status: 'asc',
          },
          icon: 'arrow_upward',
        })
      });
    }
    this.setState({ residents: newProps.residents });
  }

  componentWillUnmount() {
    store.removeReducer(RESIDENTS_DASHBOARD);
    if (this.residentsListener) { this.residentsListener() }
  }

  getResident(residentId) {
    if (residentId === null || residentId === undefined) {
      return console.error("Resident Id was an invalid value");
    }

    // get resident doc
    return this.props.firebase.getResident(residentId)
      .then(resident => {
        // update resident entry and set state
        this.props.setResident(resident);
      })
      .catch(error => {
        console.error(error);
      });
  }


  // User Input events

  onInviteResidentClicked = () => {
    this.handleInviteResidentClicked();
  };

  // Handle User Input

  handleSelectResident(residentId) {
    this.setState({ selectedResident: residentId })
  }


  handleInviteResidentClicked = () => {
    this.setState({
      inviteFirstName: null,
      inviteLastName: null,
      inviteMoveDate: null,
      inviteEmail: null,
      invitePhonenumber: null,
      info: null,
    }, () => { this.props.setShowInviteResidentDialog(true) });
  }

  handleInviteResidentCancel = () => {
    this.props.setShowInviteResidentDialog(false)
  };

  handleInviteSubmitted = () => {
    this.props.setShowLoading(true)
    const {
      inviteFirstName, inviteLastName, inviteMoveDate,
      inviteEmail, invitePhonenumber, info
    } = this.state;

    // check if invite is filled out
    if (inviteFirstName === undefined || inviteLastName === undefined ||
      inviteMoveDate === null || inviteEmail === undefined) {
      // TODO: ERROR MESSAGE
      console.error('invite was not filled out');
      return;
    }
    // call api to create invite code
    let inviteDetails = {
      firstName: inviteFirstName,
      lastName: inviteLastName,
      email: inviteEmail,
      moveDate: inviteMoveDate.valueOf(),
      phonenumber: invitePhonenumber,
      residentInfo: info,
    }
    return this.props.firebase.createInviteCodeForApartmentResident(inviteDetails)
      .then(result => this.handleInviteWasSent(result.data.invite))
      .catch(error => {
        console.error(error)
        // TODO: ERROR MESSAGE
        this.props.setShowLoading(false);
        this.props.setShowInviteResidentDialog(false);
        this.props.setSnackBar(true, "Error: Unable to send Invite Code");
      });
  };

  handleInviteWasSent = (invite) => {
    this.props.setShowLoading(false);
    this.props.setShowInviteResidentDialog(false);
    this.props.setSnackBar(true, "Invite Code Sent");
    this.handleSelectResident(invite.tempAccountId);
    this.props.firebase.inviteEmailDate(invite.tempAccountId, invite.apartment, Date.now());
  }

  handleFirstNameChange = (fname) => {
    this.setState({
      inviteFirstName: fname,
    })
  };

  handleLastNameChange = (lname) => {
    this.setState({
      inviteLastName: lname,
    })
  };

  handleEmailChange = (email) => {
    this.setState({
      inviteEmail: email,
    })
  };

  handleInfoChange = (info) => {
    this.setState({
      info: info,
    })
  };

  handleMoveDateChange = (date) => {
    this.setState({
      inviteMoveDate: date,
    })
  };

  handlePhoneNumberChange = (phonenumber) => {
    this.setState({
      invitePhonenumber: phonenumber,
    })
  }

  handleClose = () => {
    const { setSnackBar } = this.props;
    setSnackBar(false, "");
  };

  arrayToObject(array, key) {
    var residentObj = array.reduce((obj, item) => {
      obj[item[key]] = item;
      return obj;
    }, {})
    return residentObj;
  }

  showAllArchive() {
    document.getElementById('showAll').style.display = 'none';
    document.getElementById('hideArchive').style.display = 'block';
    document.getElementById('all').style.display = 'inline';
    this.setState({ showArchive: true });
  }

  hideAllArchive() {
    document.getElementById('showAll').style.display = 'block';
    document.getElementById('hideArchive').style.display = 'none';
    document.getElementById('all').style.display = 'none';
    this.setState({ showArchive: false });
  }

  handleResidentPageTabChange = (value) => {
    this.setState({ tab: value });
  }

  // view message
  handleViewMessageClicked() {
    this.handleSelectResident(this.state.msgAuthorID);
    this.setState({ tab: 2, msgBar: false });
  }

  render() {
    const {
      classes,
      showInviteResidentDialog,
      showLoading,
      showSnackBar, snackBarMessage
    } = this.props;
    const { inviteMoveDate,
      selectedResident, showArchive, tab,
    } = this.state;

    return (
      <div className="residentsdashboard">
        <div className="residentslist">
          <div className='residentsDashboardHeader'>
            <div className='drop'>
              New Residents
                            <span id={'all'} style={{ display: 'none' }}>
                (ALL)
                            </span>
              <span className='archiveDrop'>
                <i
                  className="material-icons"
                  style={{ color: 'black' }}
                >
                  arrow_drop_down
                                </i>
                <div className='dropContent' style={{ left: '70%' }}>
                  <a id={'showAll'} onClick={this.showAllArchive}>
                    Show all
                                    </a>
                  <a
                    id={'hideArchive'}
                    onClick={this.hideAllArchive}
                    style={{ display: 'none' }}
                  >
                    Hide archived
                                    </a>
                </div>
              </span>
            </div>
            <div style={{ padding: '10px' }}>
              <Button
                classes={{ root: classes.tabRoot }}
                variant='contained'
                onClick={this.onInviteResidentClicked}
              >
                <a style={{ color: 'white' }}>
                  Invite
                                </a>
              </Button>
            </div>
          </div>
          <ResidentsList
            className={classes.residentsList}
            firebase={this.props.firebase}
            authUser={this.props.authUser}
            selectedId={selectedResident}
            onSelectResident={this.handleSelectResident.bind(this)}
            icon={this.state.icon}
            showArchive={showArchive}
          />
        </div>
        <div className="line" />
        <div className="residentdashboard">
          <ResidentDashboard
            residentId={selectedResident}
            onSelectResident={this.handleSelectResident.bind(this)}
            tab={tab}
            handleTabChange={this.handleResidentPageTabChange}>
          </ResidentDashboard>
        </div>
        <InviteResidentDialog
          open={showInviteResidentDialog}
          loading={showLoading}
          moveDate={inviteMoveDate}
          onCancel={this.handleInviteResidentCancel.bind(this)}
          onInviteWasSent={this.handleInviteWasSent.bind(this)}
          onInviteSubmitted={this.handleInviteSubmitted.bind(this)}
          onFirstNameChange={this.handleFirstNameChange.bind(this)}
          onDateChange={this.handleMoveDateChange.bind(this)}
          onInfoChange={this.handleInfoChange.bind(this)}
          onLastNameChange={this.handleLastNameChange.bind(this)}
          onEmailChange={this.handleEmailChange.bind(this)}
          onPhonenumberChange={this.handlePhoneNumberChange.bind(this)}
        >
        </InviteResidentDialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSnackBar}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackBarMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ padding: '2px' }}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.msgBar}
          autoHideDuration={5000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.msgBarMessage}</span>}
          action={[
            <button
              className={'viewMessageButton'}
              onClick={this.handleViewMessageClicked}
            >
              View message
                        </button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ padding: '2px' }}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { [RESIDENTS_DASHBOARD]: residentsDashboard = INITIAL_STATE } = state;
  return ({
    apartment: state.apartmentState.apartment,
    authUser: state.sessionState.authUser,
    ...residentsDashboard
  })
}

const mapDispatchToProps = dispatch => ({
  setResident: resident => dispatch(ResidentActions.setResident(resident)),
  setResidentsListener: firebase =>
    ResidentActions.listenToApartmentResidents(firebase, dispatch),
  setShowInviteResidentDialog: show => dispatch(setShowInviteResidentDialog(show)),
  setShowLoading: show => dispatch(setShowLoading(show)),
  setSnackBar: (show, message) => dispatch(setSnackBar(show, message))
})

export default compose(
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ResidentsDashboardPage);