import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'recompose';

import { withFirebase } from '../firebase';
import { SET_AUTH_USER, SET_APARTMENT, ResidentActions } from '../actions';

const withAuthentication = Component => {
  class Authentication extends React.Component {
    constructor(props) {
      super(props);
      this.props.onSetAuthUser(
        JSON.parse(localStorage.getItem('authUser')),
      );
      this.state = { authUser: null };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.props.onSetAuthUser(authUser);
          // get apartment data
          this.props.firebase.userApartment()
            .then(apartment => {
              this.props.onSetApartment(apartment);
            })
            ;

          if (!authUser || authUser.isAnonymous) {
            this.props.removeAllResidents()
          }
        }).bind(this), error => {
          console.error("ERROR: withAuthentication:", error);
          localStorage.removeItem('authUser');
          this.props.onSetAuthUser(null);
          this.props.onSetApartment(null);
        },
      );
    }

    shouldComponentUpdate(newProps, newState) {
      return newProps.authUser !== this.props.authUser
    }

    componentWillUnmount() { this.listener(); }

    render() {
      return this.props.authUser !== undefined || this.props.authUser !== null
        ? <Component {...this.props} />
        : null;
    }
  };

  // sets the new state for the store
  const mapStateToProps = state => {
    const { sessionState = { authUser: null } } = state;
    return sessionState
  }



  const mapDispatchToProps = dispatch => ({
    removeAllResidents: () => dispatch(ResidentActions.removeResidents()),
    onSetAuthUser: authUser =>
      dispatch({
        type: SET_AUTH_USER,
        authUser
      }),
    onSetApartment: apartment =>
      dispatch({
        type: SET_APARTMENT,
        apartment
      }),
  })

  return compose(
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps),
  )(Authentication);
};

export default withAuthentication;