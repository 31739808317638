import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose, fromRenderProps } from 'recompose';
import Gallery from 'react-grid-gallery';

import Colors from '../../config/colors';
import { withAuthentication } from '../../session';

class GalleryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            images : []
        };
    }

    componentWillReceiveProps(newProps) {
        this.props.firebase.getAllGalleryPhotos().then(photosData => {
            const photos = photosData.map(photoData => {
                return {
                    src : photoData.url,
                    thumbnail : photoData.url,
                    thumbnailWidth : 300,
                    thumbnailHeight : 300,
                    isSelected : false,
                    caption : photoData.caption,
                }
            })
            console.log(photos)
            this.setState({images : photos});
        })
    }

    handleChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.setState(() => ({ image }));
        }
    };
    
    handleUpload = () => {
        const { image } = this.state;
        const uploadTask = this.props.firebase.storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({ progress });
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                this.props.firebase.storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    this.setState({ url });
                });
            }
        );
    };

    render() {
        const { images } = this.state;
        return (
            <div className="center">
                <Gallery images={images}/>
                <div className="file-field input-field">
                    <div className="btn">
                        <span>File</span>
                        <input type="file" onChange={this.handleChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" />
                    </div>
                </div>
                <button
                    onClick={this.handleUpload}
                    className="waves-effect waves-light btn"
                >
                Upload
                </button>
            </div>
        ) 
    }
}

export default withAuthentication(GalleryPage);