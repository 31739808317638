import { 
    createStore, Reducer, combineReducers, 
    applyMiddleware, Store 
} from 'redux';
import createSagaMiddleware from "redux-saga";
import StaticReducers from '../reducers';

export const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
    const store = createStore(
        combineReducers({...StaticReducers}), 
        applyMiddleware(sagaMiddleware)
    );
    
    store.asyncReducers = {};

    store.injectReducer = function(key, asyncReducer) {
        console.log('Injecting: ', key, ' to store')
        store.asyncReducers[key] = asyncReducer;
        const updatedReducer = combineReducers({
            ...StaticReducers,
            ...store.asyncReducers,
        });
        store.replaceReducer(updatedReducer);
        console.log('Adding Reducer: ', key)
    };

    store.removeReducer = function(key) {
        delete store.asyncReducers[key];
        delete store.getState()[key];
        console.log('Removing Reducer: ', key)
    }
    
    return store;
}
