import React from 'react';
import {
    Typography
} from "@material-ui/core"
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { selectMenuItemAction } from '../actions';
import './SideMenu.css';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        width: 'fit-content',
        left: 0,
        maxWidth: '250px'
    },
    paper: {
    },
    build: {
        float: "right",
        margin: "10px",
        position: "absolute",
        bottom: "0"
    }
}));

const menuItems = [
    { id: "generalInfo", title: "General Info" },
    { id: "photos", title: "Photos" },
    { id: "requiredMoveInTasks", title: "Required Move-in Tasks" },
    { id: "postMoveInTasks", title: "Post Move-in Tasks" },
    { id: "faqs", title: "FAQs" },
    { id: "importantPhoneNumbers", title: "Important Phone Numbers" },
    { id: "team", title: "Meet the Team" },
    { id: "amenities", title: "Amenities" },
    { id: "emailSettings", title: "Email Settings" },
    { id: "internalTasks", title: "Internal Tasks" }
]

const SideMenu = (props) => {
    const classes = useStyles();
    const { selectMenuItem, selectedMenuItem } = props;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <MenuList>
                    {menuItems.map((item) => (
                        <MenuItem
                            key={item.id}
                            onClick={() => selectMenuItem(item.id)}
                            selected={item.id === selectedMenuItem}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
                </MenuList>
                <Typography className={classes.build}>
                    MuvnDay v0.1
                </Typography>
            </Paper>
        </div>
    );
}

SideMenu.propTypes = {
    onSideMenuSelection: PropTypes.func
}


const mapStateToProps = (state) => {
    const { SideMenu = {} } = state;
    return SideMenu;
}

const mapDispatchToProps = dispatch => {
    return {
        selectMenuItem: (selectedPage) =>
            dispatch(selectMenuItemAction(selectedPage)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideMenu);