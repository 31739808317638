import React from 'react';

const AuthUserContext = React.createContext(null);
const ResidentsContext = React.createContext(null);
const MessageContext = React.createContext(null);
const NotificationContext = React.createContext(null);

export default AuthUserContext;

export { 
    ResidentsContext, MessageContext, NotificationContext 
};