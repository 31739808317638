import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import {
    Button, CircularProgress,
    TextField, createMuiTheme, CardMedia,
} from '@material-ui/core';
import { PostActions } from '../../actions';
import { withFirebase } from '../../firebase'

const theme = createMuiTheme({
    palette: {
        primary: { main: "#36ba8c" },
        secondary: { main: '#0181ff' },
    },
});

const styles = theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: "column"
    },
    createPostMiddle: {
        display: "flex",
        margin : '5px'
    },
    uploadPostContainer: {
        display: 'block',
        height: 'fit-content',
        'min-height': '50px',
        'margin-top': '5px',
    },
    uploadPostImage: {
        height: 'fit-content',
        float: 'left',
        'max-height': '300px',
    },
    uploadPostButton: {
        position: 'relative',
        float: 'left',
        margin: '10px',
        backgroundColor: '#36ba8c',
    },
    imageInput: {
        display : 'none'
    },
    createPostTextField: {
        width: "'100%'",
        margin: "5px",
        float: "right",
        "min-height": "40px"
    },
    postButton: {
        backgroundColor: '#36ba8c',
        borderRadius: theme.spacing.unit * 1,
        margin: "5px",
        float: "left",
        width: "100px",
        height: "40px",
        clear: "both",
        position: "relative",
        bottom: "0px",
        right: "0px"
    },
    loadingView: {
        height: 'calc(100% - 27px)',
        width: 'calc(100% - 34px)',
        backgroundColor: '#ffffffaa',
        top: 0,
        left: 0,
        margin: '11px 16px 17px 17px',
        position: 'absolute',
    },
    loadingSpinner: {
        color: '#36ba8c',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

class CreatePost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.createPostTextRef = React.createRef();
        this.onSelectImage = this.onSelectImage.bind(this);
        this.onCreateNewPost = this.onCreateNewPost.bind(this);
    }

    onCreateNewPost() {
        // TODO: set the loading for page
        const postText = this.createPostTextRef.current.value;
        const { uploadImageURL : imageURL } = this.state
        this.setState({ loading: true })
        return PostActions
        .createPost(this.props.firebase,
            postText,
            ['community'],
            imageURL 
                ? { sourceType: 'image', url : imageURL }
                : {}
        )
        .then(result => {
            this.createPostTextRef.current.value = ''
            this.props.onPostCreated()
            this.setState({uploadImageURL : null, loading: false})
        });
    }

    onSelectImage(e) {
        var { files } = e.target;
        this.setState({ loading: true })
        if (files) {
            files = Array.from(files);
            if (!files[0]) { 
                this.setState({ loading: false })
                return 
            }
            const image = files[0];
            // upload Image
            return PostActions.uploadImage(this.props.firebase, image)
            .then(upload => {
                console.log(upload);
                const { uploadRef, uploadListener } = upload;
                const next = event => {
                    const { bytesTransferred, totalBytes } = event;
                    if (bytesTransferred === totalBytes) {
                        console.log('Completed');
                        const getImageURL = function () {
                            return uploadRef.getDownloadURL()
                            .catch(error => {
                                console.error(error)
                                return getImageURL()
                            })
                        }.bind(this)
                        return getImageURL().then(imageURL => {
                            this.setState({ uploadImageURL : imageURL, loading: false })
                        })
                    }
                };
                const error = error => console.error(error);
                const completed = () => {this.setState({ loading: false })};
                uploadListener(next, error, completed);
            });
        }
    }

    render() {
        // TODO: Disable Post Button on Create Post
        const { classes } = this.props;
        const { uploadImageURL, loading } = this.state;
        return (
            <div className={classes.root}>
                <CardMedia 
                    style={{
                        height: uploadImageURL ? '300px' : 'fit-content',
                        float: 'left',
                        'max-height': '300px',
                    }}
                    image={uploadImageURL}
                >
                    <input 
                        accept="image/*"
                        className={classes.imageInput} 
                        type="image" 
                        onChange={this.onSelectImage}
                        type="file"
                        id="upload-image"
                    />
                    <label htmlFor="upload-image">
                        <Button 
                            className={classes.uploadPostButton} 
                            component="span"
                        >
                            <i class="material-icons">
                                add_photo_alternate
                            </i>
                        </Button>
                    </label>
                </CardMedia>
                <div className={classes.createPostMiddle}>
                    <TextField 
                        className={classes.createPostTextField}
                        placeholder="Share an announcement or event..."
                        fullWidth
                        multiline
                        inputRef={this.createPostTextRef}
                        align="center"
                    />
                    
                    <Button 
                        variant='containted' 
                        onClick={this.onCreateNewPost}
                        classes={{root:classes.postButton}}
                    >
                        <a style={{color:'white'}}>
                            POST
                        </a>
                    </Button>
                </div>
                {loading && 
                    <div className={classes.loadingView}> 
                        <CircularProgress className={classes.loadingSpinner}/>
                    </div>
                }
            </div> 
        );
    }
}

export default compose(
    withStyles(styles(theme)),
    withFirebase,
)(CreatePost)