import sessionReducer from './sessionReducer';
import apartmentReducer from './apartmentReducer';
import residentsReducer from  './residentsReducer'

const StaticReducers = {
    sessionState : sessionReducer,
    apartmentState : apartmentReducer,
    residentsState : residentsReducer,
};

export default StaticReducers;