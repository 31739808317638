import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  List,
  ListItem,
  MuiThemeProvider,
  Typography,
  Box,
  Link,
  Paper,
  Button,
  Snackbar,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ResidentActions } from '../../actions/actions';
import { withFirebase } from '../../firebase';
import theme from '../../theme';
import "./ResidentTaskPage.css"

const mapDispatchToProps = (dispatch) => ({
  listenToResidentTasks: (firebase, residentId) =>
    ResidentActions.listenToResidentTasks(firebase, dispatch, residentId)
});

class ResidentTaskPage extends React.Component {
  constructor(props) {
    super(props);
    const { tasks, resident, inviteCode, inviteSendDate } = props;
    const requiredTasks = tasks
      ? tasks
        .filter(task => !task.tags || task.tags.includes('move-in'))
        .sort(this.taskSort)
      : [];
    const postMoveInTasks = tasks
      ? tasks
        .filter(task => task.tags && task.tags.includes('post-move-in'))
        .sort(this.taskSort)
      : [];
    this.state = {
      resident: resident,
      tasks: requiredTasks ?? [],
      postMoveInTasks: postMoveInTasks ?? [],
      inviteCode: inviteCode,
      loading: false,
      snackBar: false,
      snackBarMsg: '',
      inviteSendDate: inviteSendDate
    };
    this.renderFieldContent = this.renderFieldContent.bind(this);
    this.renderFormField = this.renderFormField.bind(this);
  }

  componentDidMount() {
    const { resident } = this.props;
    if (resident && resident.id
      && (this.props.resident.id !== resident.id
        || this.tasksListener === undefined
      )
    ) {
      if (this.tasksListener !== undefined) { this.tasksListener(); }
      this.props.listenToResidentTasks(this.props.firebase, resident.id)
        .then(listener => { this.tasksListener = listener })
        .catch(console.error);
    }
  }

  componentWillReceiveProps(newProps) {
    const { tasks, resident, inviteCode, inviteSendDate } = newProps;
    if (this.props.resident && resident && resident.id
      && (this.props.resident.id !== resident.id
        || this.tasksListener === undefined
      )
    ) {
      if (this.tasksListener !== undefined) { this.tasksListener() }
      this.props.listenToResidentTasks(this.props.firebase, resident.id)
        .then(listener => {
          this.tasksListener = listener;
        })
        .catch(console.error);
    }
    const requiredTasks = tasks
      ? tasks
        .filter(task => !task.tags || task.tags.includes('move-in'))
        .sort(this.taskSort)
      : [];
    const postMoveInTasks = tasks
      ? tasks
        .filter(task => task.tags && task.tags.includes('post-move-in'))
        .sort(this.taskSort)
      : [];
    this.setState({
      resident: resident,
      tasks: requiredTasks,
      postMoveInTasks: postMoveInTasks,
      inviteCode: inviteCode,
      inviteSendDate: inviteSendDate,
    });
  }

  componentWillUnmount() {
    if (this.tasksListener !== undefined) { this.tasksListener() }
  }

  taskDate(timestamp) {
    var date = new Date(timestamp);
    var hr = date.getHours();
    var yr = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var min = date.getMinutes();
    var am_pm = hr >= 12 ? 'PM' : 'AM';
    if (hr > 12) { hr = hr - 12 }
    else if (hr === 0) { hr = 12 }
    if (min < 10) { min = '0' + min }
    switch (month) {
      case 1: month = "January";
        break;
      case 2: month = "February";
        break;
      case 3: month = "March";
        break;
      case 4: month = "April";
        break;
      case 5: month = "May";
        break;
      case 6: month = "June";
        break;
      case 7: month = "July";
        break;
      case 8: month = "August";
        break;
      case 9: month = "September";
        break;
      case 10: month = "October";
        break;
      case 11: month = "November";
        break;
      case 12: month = "December";
        break;
      default:
        break;
    }

    return <a style={{ marginTop: '1px' }}> {
      `${month} ${day}, ${yr} at ${hr}:${min} ${am_pm}`
    }</a>;
  }

  resendCode() {
    var code = this.state.resident.inviteCode;
    var residentID = this.state.resident.id;
    var apartment = this.state.resident.apartment;
    this.setState({ loading: true });
    var codeObj = { inviteCode: code };
    this.props.firebase.resendCode(codeObj)
      .then(result => {
        this.props.firebase.inviteEmailDate(residentID, apartment, Date.now())
        this.setState({
          loading: false,
          snackBar: true,
          snackBarMsg: 'Invite Code Sent',
        });

      })
      .catch(error => {
        // TODO: ERROR MESSAGE
        this.setState({
          loading: false,
          snackBar: true,
          snackBarMsg: 'Error: Unable to send Invite Code',
        });
      });
  }

  renderFieldContent = field => {
    switch (field.input) {
      case "text": return `: ${field.value}`
      case "phonenumber": return `: ${field.value}`
      case "yesNo": return `: ${field.value}`
      case "none": return ''
      case "file": return field.value === ""
        ? <div style={{ display: "inline" }}>
          <a>: N/A</a>
        </div>
        : <div style={{ display: "inline" }}>
          <Link href={field.value}>: File</Link>
        </div>
      default: return `: ${field.value}`
    }
  }

  renderFormField = field => (
    <div style={{ paddingLeft: '60px' }}>
      {`${field.prompt}`}
      {this.renderFieldContent(field)}
    </div>
  )

  handleClose = () => {
    this.setState({ snackBar: false });
  }

  taskSort = (task1, task2) => {
    if (task1.dueDate && task2.dueDate) {
      return task1.dueDate < task2.dueDate
    }
  }

  render() {
    const { tasks, inviteCode, postMoveInTasks } = this.state;
    const inviteSendDate = this.taskDate(this.state.inviteSendDate)
    return (
      <div className="resident-info-page">
        <Paper elevation={0}>
          <List className="resident-tasks-list" id='taskInfo' style={{ display: 'block', overflow: 'auto' }}>
            {tasks.length !== 0
              ? tasks.map((task) =>
                <ListItem divider>
                  <div className="residentTaskItem">
                    <div className="taskHeader">
                      {task.completedDate > 0
                        ? <i
                          style={{ color: 'green', fontSize: "38.5px" }}
                          className="material-icons"
                        >
                          {"check"}
                        </i>
                        : <i
                          style={{ color: 'red', fontSize: "38.5px" }}
                          className="material-icons"
                        >
                          {"close"}
                        </i>
                      }
                      {'\xa0\xa0'}
                      <Box fontSize={"18px"} style={{ marginTop: "7px" }}>
                        {task.title ? task.title : task.taskName}
                      </Box>
                    </div>
                    <Box fontSize={"18px"}>
                      {
                        task.submissions.length > 0
                          ? task.submissions[task.submissions.length - 1]
                            .fields
                            .map((field) =>
                              this.renderFormField(field)
                            )
                          : ""
                      }
                      <div>
                        {
                          task.completeDate
                            ? task.completedDate !== 0
                              ? <div style={{ paddingLeft: '20px' }}>
                                <sub>
                                  <i>Completed {this.taskDate(task.completedDate)}</i>
                                </sub>
                              </div>
                              : ''
                            : ''
                        }
                      </div>
                    </Box>
                  </div>
                </ListItem>
              )
              : inviteCode
                ? <div className="invite-code" style={{ padding: '16%', display: 'block' }}>
                  <strong>Pending resident app login</strong>
                  <p>Invite Code sent on {inviteSendDate} </p>
                  <p>Invite Code: <strong>{inviteCode}</strong></p>
                  <Button variant="outlined" onClick={() => { this.resendCode() }}>
                    Resend Invite
                  </Button>
                </div>
                : <div>loading ...  <br /><CircularProgress style={{ color: '#36ba8c' }} /></div>
            }
            {postMoveInTasks.length > 0 &&
              <span>
                <ListItem divider>
                  <Typography variant="button">
                    <Box fontWeight="fontWeightBold" fontSize={15}>
                      {"Post Move In Tasks"}
                    </Box>
                  </Typography>
                </ListItem>
                {postMoveInTasks.map((task) =>
                  <ListItem divider>
                    <div className="residentTaskItem">
                      <div className="taskHeader">
                        {task.completedDate > 0
                          ? <i
                            style={{ color: 'green', fontSize: "38.5px" }}
                            className="material-icons"
                          >
                            {"check"}
                          </i>
                          : <i
                            style={{ color: 'red', fontSize: "38.5px" }}
                            className="material-icons"
                          >
                            {"close"}
                          </i>
                        }
                        {'\xa0\xa0'}
                        <Box fontSize={"18px"} style={{ marginTop: "7px" }}>
                          {task.title ? task.title : task.taskName}
                        </Box>
                      </div>
                      {
                        task.submissions.length > 0
                          ? task.submissions[task.submissions.length - 1]
                            .fields
                            .map((field) =>
                              this.renderFormField(field)
                            )
                          : ""
                      }
                      <div>
                        {
                          task.completeDate
                            ? task.completedDate !== 0
                              ? <div style={{ paddingLeft: '20px' }}>
                                <sub>
                                  <i>Completed {this.taskDate(task.completedDate)}</i>
                                </sub>
                              </div>
                              : ''
                            : ''
                        }
                      </div>
                    </div>
                  </ListItem>
                )
                }
              </span>
            }
          </List>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBar}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackBarMsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ padding: '2px' }}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <MuiThemeProvider theme={theme}>
          {this.state.loading && <CircularProgress> </CircularProgress>}
        </MuiThemeProvider>
      </div>
    )
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withFirebase,
)(ResidentTaskPage);