import React from 'react'
import { compose } from 'recompose';
import { withFirebase } from '../../../firebase'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from "material-ui-pickers";
import { MuiThemeProvider } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import theme from '../../../theme';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  inviteProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: 5,
    width: 260,
    marginRight: 10
  },
  three: {
    margin: 5,
    width: 170,
    marginRight: 5
  },
  date: {
    margin: 5,
    width: 150
  },
  cssLabel: {
    '&$cssFocused': {
      color: "#36ba8c",
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: "#36ba8c",
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "#36ba8c",
    },
  },
  notchedOutline: {},
  datePicker: {
    selectColor: "red",
  },
  inviteBtn: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#43e8ae',
    },
    backgroundColor: '#36ba8c'
  },
  phonenumberField: {
    border: 'none',
    width: '67%',
    fontSize: '16px',
    margin: 5,
    marginTop: '25px',
    borderBottom: 'solid 1px rgb(0, 0, 0, 0.38)',
    paddingBottom: '5px',
    marginBottom: '5px',
    '&$cssFocused $notchedOutline': {
      borderBottom: "2px solid #36ba8c",
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
      borderBottom: "2px solid #36ba8c",
    },
    '&:after': {
      outline: 'none',
      borderBottom: "2px solid #36ba8c",

    },
    '&:focus': {
      outline: 'none',
      borderBottom: "2px solid #36ba8c",
    },
    '&:hover': {
      borderBottom: 'solid 2px black',
      '&$cssFocused': {
        color: "#36ba8c",
      },

    }
  },
  phoneLabel: {
    fontSize: "12px",
    color: 'rgb(0, 0, 0, 0.643)',
    marginBottom: '4px',
    width: '67%',
  }
};

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}


class InviteResidentDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      onCancel: props.onCancel,
      onInviteSubmitted: props.onInviteSubmitted,
      onFirstNameChange: props.onFirstNameChange,
      onLastNameChange: props.onLastNameChange,
      onPhonenumberChange: props.onPhonenumberChange,
      onEmailChange: props.onEmailChange,
      loading: false,
      selectedDate: null,
    };
  };

  componentWillReceiveProps(props) {
    this.setState({
      loading: props.loading,
      open: props.open,
    });
    this.fetchInfo();
  };

  fetchInfo = async () => {
    await this.props.firebase.userApartment().then(info => {
      this.setState({ mailingAddress: info.displayAddress });
    })
      .catch(console.error)
  }

  validate = () => {
    var firstName = document.getElementById('fname').value;
    var lastName = document.getElementById('lname').value;
    var email = document.getElementById('email').value;
    var date = this.state.selectedDate;
    var errorFname = document.getElementById('errorfname')
    var errorLname = document.getElementById('errorlname')
    var errorEmail = document.getElementById('erroremail')
    var errordate = document.getElementById('errordate')
    const regularEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    errordate.innerHTML = '';
    errorEmail.innerHTML = '';
    errorFname.innerHTML = '';
    errorLname.innerHTML = '';
    if (firstName.length === 0) {
      errorFname.innerHTML += "This field is required";
      return false;
    }
    if (lastName.length === 0) {
      errorLname.innerHTML += "This field is required";
      return false;
    }
    if (email.length === 0) {
      errorEmail.innerHTML += "This field is required";
      return false;
    }
    if (date === undefined || date === null) {
      errordate.innerHTML += "This field is required";
      return false;
    }
    if ((firstName.length !== 0) && (lastName.length !== 0) && (email.length !== 0) && (date.length !== 0)) {
      if (!regularEXP.test(email.toLowerCase())) {
        errorEmail.innerHTML += "Please enter a valid email address";
        return false;
        // TODO: DISPLAY EMAIL ERROR TEXT
      }
    }
    return true
  }

  onCancel = () => {
    this.props.onCancel()
  };

  onInviteSubmitted = () => {
    if (this.validate()) {
      this.onInviteSubmitted();
    }
  };

  onInviteSubmitted = () => {
    // get invite values
    const {
      firstName, lastName, selectedDate: date,
      email, phonenumber, unit,
      mailbox, parkingSpace, mailingAddress,
      additionalInfo
    } = this.state;
    console.log(this.state);

    // check if invite is filled out
    if (!this.validate()) {
      // TODO: ERROR MESSAGE
      console.error('invite was not filled out');
      return;
    }

    // call api to create invite code
    let inviteDetails = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      moveDate: date.valueOf(),
      phonenumber: phonenumber,
      unit: unit,
      mailbox: mailbox,
      parkingSpace: parkingSpace,
      mailingAddress: mailingAddress,
      additionalInfo: additionalInfo,
    }

    this.setState({ loading: true });

    console.log('sending to Firebase to create InviteCode');
    console.log(inviteDetails)

    return this.props.firebase.createInviteCodeForApartmentResident(inviteDetails)
      .then(invite => {
        console.log(invite);
        this.props.onInviteWasSent(invite);
      })
      .catch(error => {
        console.log('Error: Could not create Invite Code');
        console.error(error);
      });
  }

  onDateChange = (date) => {
    this.setState({ selectedDate: date });
  }

  onFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value });
  }

  onLastNameChange = (event) => {
    this.setState({ lastName: event.target.value });
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  onPhonenumberChange = (event) => {
    this.setState({ phonenumber: event.target.value });
  }

  onUnitChange = (event) => {
    this.setState({ unit: event.target.value });
  }

  onMailboxChange = (event) => {
    this.setState({ mailbox: event.target.value });
  }

  onParkingSpaceChange = (event) => {
    this.setState({ parkingSpace: event.target.value });
  }

  onMailingAddressChange = (event) => {
    this.setState({ mailingAddress: event.target.value });
  }

  onAdditionalInfo = (event) => {
    this.setState({ additionalInfo: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const { open, selectedDate, loading, mailingAddress, forwardedRef } = this.state;
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={true}
        ref={forwardedRef}
      >
        <DialogTitle id="form-dialog-title" align="center" >Send Invite Code</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            {"Resident Details"}
          </DialogContentText>
          <form className={classes.container}>
            <TextField
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  underline: classes.cssUnderline,
                },
              }}
              autoFocus
              margin="dense"
              id="fname"
              label="First Name*"
              type="name"
              onChange={this.onFirstNameChange}
            />
            <p style={{ color: 'red' }} id='errorfname' />
            <TextField
              className={classes.margin}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  underline: classes.cssUnderline,
                },
              }}
              margin="dense"
              id="lname"
              label="Last Name*"
              type="name"
              onChange={this.onLastNameChange}
            />
            <p style={{ color: 'red' }} id='errorlname' />
            <div>
              <MuiThemeProvider theme={theme}>
                <DatePicker
                  className={classes.date}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput, 
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.cssUnderline,
                    },
                  }}
                  onlyCalendar
                  margin="dense"
                  id='date'
                  label="Move-In Date*"
                  format="M/DD/YYYY"
                  placeholder="mm/dd/yyyy"
                  mask={value =>
                    // handle clearing outside if value can be changed outside of the component
                    value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                  }
                  value={this.state.selectedDate}
                  onChange={this.onDateChange}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  disablePast={true}
                />
              </MuiThemeProvider>
            </div>
            <p style={{ color: 'red' }} id='errordate' />
            <NumberFormat
              className={classes.phonenumberField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  underline: classes.cssUnderline,
                },
              }}
              label={<span>Mobile Number</span>}
              format="(###) ###-####"
              mask=""
              placeholder="Mobile Number"
              margin="dense"
              id="phonenumber"
              type="tel"
              onChange={this.onPhonenumberChange}
            />
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  underline: classes.cssUnderline,
                },
              }}
              margin="dense"
              id="email"
              label="Email*"
              type="email"
              onChange={this.onEmailChange}
              style={{ width: 530, marginLeft: 5 }}
            />
            <p style={{ color: 'red' }} id='erroremail' />
            <br></br>
            <div>
              <DialogContentText style={{ marginTop: '30px' }} align="center">
                {"Apartment Details"}
              </DialogContentText>
              <div className={classes.container} fullWidth={true} margin={10}>
                <TextField
                  className={classes.three}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.cssUnderline,
                    },
                  }}
                  id="unit"
                  label={<span>Unit <a style={{ fontSize: '10px' }}></a></span>}
                  onChange={this.onUnitChange}
                  multiline={true}
                />
                <TextField
                  className={classes.three}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.cssUnderline,
                    },
                  }}
                  id="mailbox"
                  label={<span>Mailbox <a style={{ fontSize: '10px' }}></a></span>}
                  onChange={this.onMailboxChange}
                  multiline={true}
                />
                <TextField
                  className={classes.three}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                      underline: classes.cssUnderline,
                    },
                  }}
                  id="parkingSpace"
                  label={<span>Parking Space <a style={{ fontSize: '10px' }}></a></span>}
                  onChange={this.onParkingSpaceChange}
                  multiline={true}
                />
              </div>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    underline: classes.cssUnderline,
                  },
                }}
                style={{ margin: 5 }}
                fullWidth={true}
                margin="dense"
                id="mailingAddress"
                value={mailingAddress}
                label={<span>Mailing Address <a style={{ fontSize: '10px' }}></a></span>}
                onChange={this.onMailingAddressChange}
                multiline={true}
              />
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    underline: classes.cssUnderline,
                  },
                }}
                style={{ margin: 5 }}
                fullWidth={true}
                margin="dense"
                id="additionalInfo"
                label={<span>Additional Info <a style={{ fontSize: '10px' }}></a></span>}
                onChange={this.onAdditionalInfo}
                multiline={true}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <p style={{ marginRight: "320px", fontSize: '13px' }}>* Required field</p>
          <Button onClick={this.onCancel}>
            <a style={{ color: "#36ba8c" }}>Cancel</a>
          </Button>
          <Button className={classes.inviteBtn} onClick={this.onInviteSubmitted}>
            <a style={{ color: "white" }}>Send</a>
          </Button>
        </DialogActions>
        <MuiThemeProvider theme={theme}>
          {loading && <CircularProgress className={classes.inviteProgress}> </CircularProgress>}
        </MuiThemeProvider>
      </Dialog>
    );
  };

};

InviteResidentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withFirebase,
  withStyles(styles),
)(React.forwardRef((props, ref) =>
  <InviteResidentDialog {...props} forwardedRef={ref} />
))
